import {
  GET_CUSTOMER_SUCCESS, GET_CUSTOMER_FAILURE, GET_OTP_SUCCESS, GET_OTP_FAILURE
} from "../types/customer/types";

const initialState = {
  customer: {},
  customers: [],
  status: null,
  error: null,
  loading: true,
  otp: null
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        customer: action.payload,
        error: null
      };

    case GET_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        status: "Failed",
        error: action.payload,
        customer: {}
      };

    case GET_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        otp: "success",
        error: null,
        customer: {}
      }

    case GET_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        otp: "Failed",
        error: action.payload,
        customer: {}
      }

    default:
      return state;
  }
};

export default reducer;

