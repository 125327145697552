import React from 'react';
import { FiUser } from 'react-icons/fi';
import IncogNav from '../../../../../../components/navbar/IncogNav';
import NavbarTitle from '../../../../../../components/navbar/NavbarTitle';
import PartnerMenu from '../../../../../../components/navbar/PartnerMenu';
import PartnerOrCustomer from '../../../../../../components/navbar/PartnerOrCustomer';
import CustomerNavbar from './CustomerNavbar';

export default function NormalNavbarMenu({ type, scrolled }) {
    return (
        <div className="flex items-center pl-10">
            {type === "partner" &&
                <PartnerMenu />
            }
            {type === "super-admin" &&
                <>
                    <PartnerOrCustomer route="/choose-partner" type="partner" />
                    <PartnerOrCustomer route='/sign-in' type="customer" />
                    <NavbarTitle route="/super-admin" title="Rashel" />
                </>
            }
            {(type !== "customer" && type !== "super-admin" && type !== "partner") &&
                <>
                    <IncogNav scrolled={scrolled} route='/choose-partner' img='https://foodapps.uk/images/icons/partner.png' title="Partner" />
                    <IncogNav scrolled={scrolled} route='/sign-in' icon={FiUser} title="Customer" />
                </>
            }  
            {type === "customer" &&
                <CustomerNavbar />
             }
        </div>
    )
}