import React from 'react';

import OrderDetails from './OrderDetails';
import TotalAmount from './TotalAmount';
import Header from './Header';
import Saved from './Saved';
export default function OrderReceived( ) {
    return (
        <div className="bg-nat">
            <div className="w-full py-8 sm:px-40 md:px-40">
                <div className="bg-white rounded-lg border w-full h-full p-5">
                    <Header />
                    <div className="px-10 mt-4">
                        <div className="flex items-center justify-between">
                            <div>
                                <p className="text-2xl font-bold">Order Details</p>
                            </div>
                            <Saved />
                        </div>
                        <div id="orderReceived" className="font-bold flex flex-wrap items-center justify-between mt-3">
                            <OrderDetails />
                            <TotalAmount />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}