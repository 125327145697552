import React from 'react';
import { FiSearch } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import NormalNavbarMenu from '../NormalNavbar/NormalNavbarMenu';

function ScrolledNavbar() {
    return (
        <div>
            {/* <MobileNavbar />    */}
            <div className="hidden sm:hidden md:block">
                <div>
                    <div>
                        <div className="flex flex-wrap fixed top-0 items z-7 bg-white items-center w-full shadow-lg">
                            <div className="pl-10 flex-grow  py-2">
                                <Link to='/'>
                                    <img alt="foodapps.uk" className="h-16 cursor-pointer" src="/img/logos/FoodApps_rider_logo.png" />
                                </Link>
                            </div>
                            <div className="w-1/2  py-2">
                                <div className="w-full bg-nat rounded-lg flex items-center">
                                    <div className="px-2">
                                        <FiSearch className="text-gray-500 text-lg" />
                                    </div>
                                    <input className="px-1 p-2 flex-grow outline-none bg-nat" type="text" placeholder="Enter your post code" />

                                    <button type="button" className="bg-prim text-white font-semibold rounded-r-lg p-4 outline-none">Search</button>
                                </div>
                            </div>
                            <NormalNavbarMenu scrolled={true} type="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScrolledNavbar;