import {
    GET_ORDER_SUCCESS,
    GET_ORDER_FAILURE,
    GET_ALL_ORDERS_SUCCESS,
    DELETE_ORDER_SUCCESS
} from "../types/order/type";
import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";

export const getDriverOrders = async (dispatch, driverId, token) => {
    try {
        const { data: { data } } = await axios.get(`${apiBaseUrl}${apiVersionPrefix}/driver/list-order`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        if (data) {
            console.log('Driver orders', data);
            dispatch({ type: GET_ALL_ORDERS_SUCCESS, payload: data })
        }
    } catch (err) {

        const { response: { data: { error } } } = err;
        console.log("error >>", error)
        dispatch({ type: GET_ORDER_FAILURE, payload: error })
    }
}

export const getOrderDetails = async (dispatch, orderId, token) => {
    try {
        const { data: { data } } = await axios.get(`${apiBaseUrl}${apiVersionPrefix}/order/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        if (data) {
            dispatch({ type: GET_ORDER_SUCCESS, payload: data })
        }
    } catch (err) {

        const { response: { data: { error } } } = err;
        console.log(error)
        dispatch({ type: GET_ORDER_FAILURE, payload: error })
    }
}

