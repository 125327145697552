import React from 'react'
import { Link } from 'react-router-dom'
export default function SidebarButton(props) {
    
    return (
        <Link to={props.route}>
            <div onClick={props.onClick} style={props.containerStyle} className={`${props.active? "border border-red-700": ""} my-2 cursor-pointer flex items-center rounded-md hover:bg-red-700 text-gray-700 hover:text-white p-2 hover:shadow-md ${props.containerClasses}`}>
                <div style={props.iconStyle} className={`p-2 bg-white border-red-700 border shadow-lg rounded-full text-lg ${props.iconClasses}`}>
                    <props.icon className="text-gray-700" />
                </div>
                <div style={props.textStyle} className={`hidden sm:block md:block px-4 ${props.textClasses}`}>
                    <p className="font-semibold">{props.text}</p>
                </div>
            </div>
        </Link>
    )
}
