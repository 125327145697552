import React, { useEffect } from "react";
//
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
//
import UserLayout from "../../../components/layout/UserLayout";
import Button from "../../../components/utilities/buttons/Button";
import Heading from "../../../components/utilities/Heading";
import MyOrder from "../../../components/myOrder";
import { useDispatch, useSelector } from "react-redux";
import { getDriverOrders } from "../../../redux/actions/order";
import { getCookie } from "../../../components/utilities/cookie/cookie";
import Tabs from "../../../components/utilities/Tabs";

const order1 = {
  orderId: "TES063",
  type: "Collected",
  status: "21 / Aug - 08:28 AM",
  collection: "30 Min",
  paymentMethod: "card",
  itemPrice: "65",
  total: "78",
};

export default function UserMyOrder() {
  const driver = JSON.parse(localStorage.getItem("driver"));

  const driverId = driver._id;

  const dispatch = useDispatch();

  const token = getCookie("token");

  useEffect(() => {
    getDriverOrders(dispatch, driverId, token);
  }, [dispatch, driverId, token]);

  const { orders, error } = useSelector((state) => state.order);

  return (
    <HelmetProvider>
      <Helmet>
        <title>FoodApps | My Deliveries</title>
      </Helmet>
      <UserLayout>
        <Heading>My Deliveries</Heading>
        <div className="mt-4">
          <div className="px-6 flex justify-between">
            <Tabs variant="prim" tabs={["All Deliveries", "Pending Deliveries"]} />
          </div>
          {/* <div className="px-4 flex flex-wrap w-full items-center mt-4">
            <div className="w-full md:w-3/3 p-2">
              <MyOrder order={order1} />
            </div>
          </div> */}
        </div>

        <div className="flex flex-wrap items-center mt-4">

          {orders && orders.length > 0 ? (
            orders.map((order, index) => (
              <MyOrder key={index} order={order} />
            ))
          ) : <span className="text-red-700 font-bold text-lg">No orders is found!</span>
          }
        </div>

        {/* <div className="flex flex-wrap items-center mt-4">
                <div className="px-8 py-6">
                    <p className="text-2xl font-thin">No Orders Yet!</p>
                </div>
            </div> */}
      </UserLayout>
    </HelmetProvider>
  );
}
