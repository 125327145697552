
import React from 'react';

import Field from '../../../components/utilities/form/Field';

export default function PaymentInfoContent(props) {
    return (
        <div className="w-full md:w-1/3 mt-2">
            <p className="text-sm flex items-center">
                {props.title} <span className="ml-1 text-xs font-bold text-gray-600"> {props.subTitle}</span>
            </p>
            <Field
                style={{ borderRadius: ".5rem" }}
                classes="py-2 px-3"
                placeholder={props.placeholder}
            />
        </div>
    )
}