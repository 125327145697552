
import React,{useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import UserLayout from '../../../components/layout/UserLayout';
import Heading from '../../../components/utilities/Heading';
import { getTermsAndCondition } from '../../../redux/actions/termsAndConditions';
import "./index.css";

export default function UserTermsOfUse() {

    const {termsAndCondition} = useSelector(state => state.termsAndConditions);
    const dispatch = useDispatch();

    useEffect(()=>{
        getTermsAndCondition(dispatch,"User");
    })
    
    return (
        <HelmetProvider>
        <Helmet>
            <title>FoodApps | Customer Terms And Conditions</title>
        </Helmet>
        <UserLayout>
            <Heading>Terms And Conditions</Heading>
            <div className="termsHtml"dangerouslySetInnerHTML={{__html:termsAndCondition.description}}></div>
        </UserLayout>
        </HelmetProvider>
    )
}