import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
//
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
//
import UserLayout from "../../../components/layout/UserLayout";
import Button from "../../../components/utilities/buttons/Button";
import Heading from "../../../components/utilities/Heading";
import MyOrder from "../../../components/myOrder";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../../components/utilities/cookie/cookie";
import { getOrderDetails } from "../../../redux/actions/order";

export default function OrderSummary() {
	const params = useParams();
	const orderId = params.id;
	const dispatch = useDispatch();
	const token = getCookie('token');

	useEffect(() => {
		getOrderDetails(dispatch, orderId, token);
	}, [dispatch, orderId, token]);

	const { order, error } = useSelector((state) => state.order);

	return (
		<HelmetProvider>
			<Helmet>
				<title>FoodApps | Partner Inbox</title>
			</Helmet>
			<UserLayout>
				<Heading>My Delivery</Heading>
				<Link to="/my-deliveries">
					<Heading>
						<AiOutlineArrowLeft className="inline" /> Delivery Details
					</Heading>
				</Link>

				<h6 className="mt-5 font-semibold">
					{order?.address?.postCode} <span className="text-gray-600 ml-5">Payment Method : </span>
					<span className="text-red-600">{order?.paymentMethod}</span>
				</h6>

				<div className="mt-5 border-b-2 border-gray-600" />

				<div className="flex justify-between mt-5">
					<div>
						<h4 className="font-medium">Collection From:</h4>
						<h4 className="font-medium">{order?.restaurant?.name}</h4>
						<h5 className="font-medium text-sm">
							{order?.address?.address}
						</h5>
					</div>
					<div>
						<h4 className="font-medium">Delivery To:</h4>
						<h4 className="font-medium">{order?.customerName}</h4>
					</div>
				</div>

				<h6 className="mt-5 text-xs font-medium">Kitchen Notes: {order?.kitchenNotes}</h6>

				<div className="flex justify-between mt-5">
					<div>
						<h5 className="font-medium text-sm">3 x Soup</h5>
					</div>
					<div>
						<h5 className="font-medium">&#163; 6.00</h5>
					</div>
				</div>

				<div className="flex justify-between mt-5">
					<div>
						<h5 className="font-medium text-sm">3 x Chapati</h5>
					</div>
					<div>
						<h5 className="font-medium">&#163; 3.00</h5>
					</div>
				</div>

				<div className="mt-5 border-b-2 border-gray-600" />

				<div className="flex justify-between mt-5">
					<div>
						<h5 className="font-medium text-sm">Subtotal</h5>
					</div>
					<div>
						<h5 className="font-medium">&#163; {order?.subTotal}</h5>
					</div>
				</div>

				<div className="flex justify-between mt-5">
					<div>
						<h5 className="font-medium text-sm">Discount</h5>
					</div>
					<div>
						<h5 className="font-medium">&#163; 0.90</h5>
					</div>
				</div>

				<div className="flex justify-between mt-5">
					<div>
						<h5 className="font-medium text-sm">Delivery Charges</h5>
					</div>
					<div>
						<h5 className="font-medium">&#163; 0.00</h5>
					</div>
				</div>

				<div className="flex justify-between mt-5">
					<div>
						<h5 className="font-medium text-sm">Service Charges</h5>
					</div>
					<div>
						<h5 className="font-medium">&#163; 0.50</h5>
					</div>
				</div>

				<div className="mt-5 border-b-2 border-gray-600" />

				<div className="flex justify-between mt-5">
					<div>
						<h5 className="font-medium text-sm">Total</h5>
					</div>
					<div>
						<h5 className="font-medium">&#163; {order?.totalPrice}</h5>
					</div>
				</div>
			</UserLayout>
		</HelmetProvider>
	);
}
