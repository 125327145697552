import firebase from 'firebase/app'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyCx-BrqewRuOP9l-G60_tH4-vMXW2XuABk",
    authDomain: "foodappsuk3715.firebaseapp.com",
    projectId: "foodappsuk3715",
    storageBucket: "foodappsuk3715.appspot.com",
    messagingSenderId: "815821706955",
    appId: "1:815821706955:web:1f397ea36ca080ad4fed4e",
    measurementId: "G-V79G65Z40V"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase
