import {
  GET_RESTAURANT_TIMING_FAILURE,
  GET_RESTAURANT_TIMING_SUCCESS,
} from "../types/restaurantTiming/type";

const initialState = {
  restaurantTiming: {},
  status: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESTAURANT_TIMING_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        restaurantTiming: action.payload,
      };

    case GET_RESTAURANT_TIMING_FAILURE:
      return {
        ...state,
        loading: false,
        status: "Failed",
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
