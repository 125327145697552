import React from 'react'

export default function RoundedImage(props) {

    return (
        <div style={props.containerStyle} className={`w-16 md:w-24 h-16 md:h-24 mx-2 p-2 rounded-full bg-nat shadow-full mb-2 ${props.containerClasses}`}>
            <div style={props.imgContainerstyle} className={`w-full h-full rounded-full bg-white overflow-hidden ${props.imgContainerClasses}`}>
                <img style={props.imgStyle} className={`w-full h-full object-cover ${props.imgClasses}`} src={props.src} alt={props.alt} />
            </div>
        </div>
    )
}            
