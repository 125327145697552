import {
    GET_ORDER_SUCCESS,
    GET_ORDER_FAILURE,
    GET_ALL_ORDERS_SUCCESS,
    DELETE_ORDER_SUCCESS
} from "../types/order/type";

const initialState = {
    order: {},
    orders: [],
    error: "",
    msg: "",
    status: "",
    loading: false,
    success: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                order: action.payload,
                success: true,
                error: ""
            };

        case GET_ALL_ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                error: "",
                orders: action.payload,
                success: true
            };
        case DELETE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                error: "",
                orders: action.payload
            }
        case GET_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                status: "Failed",
                error: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;