import {
  GET_DRIVER_SUCCESS,
  GET_DRIVER_FAILURE,
  GET_OTP_SUCCESS,
  GET_OTP_FAILURE,
  GET_NEARBY_RESTAURANT_SUCCESS,
  GET_NEARBY_RESTAURANT_FAILURE
} from "../types/driver/types";

const initialState = {
  driver: {},
  drivers: [],
  status: null,
  error: null,
  loading: true,
  otp: null
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        driver: action.payload,
        error: null
      };

    case GET_DRIVER_FAILURE:
      return {
        ...state,
        loading: false,
        status: "Failed",
        error: action.payload,
        driver: {}
      };

    case GET_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        otp: "success",
        error: null,
        driver: {}
      }

    case GET_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        otp: "Failed",
        error: action.payload,
        driver: {}
      }

    case GET_NEARBY_RESTAURANT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        nearbyRestaurants: action.payload,
        error: null
      };

    case GET_NEARBY_RESTAURANT_FAILURE:
      return {
        ...state,
        loading: false,
        status: "Failed",
        nearbyRestaurants: {},
        error: null
      };

    default:
      return state;
  }
};

export default reducer;

