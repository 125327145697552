import React from 'react';
export default function ItemOption({title, price}) {
    return (
        <div className="px-4 py-1 hover:text-red-700 flex items-center justify-between trans cursor-pointer">
            <div className="flex items-center justify-between w-full pr-4">
                <p className="text-lg">{title}</p>
                <p className="text-xs font-bold">+ £{price}</p>
            </div>
            <div className="w-5 h-5 rounded-full border border-red-700 p-1">

            </div>
        </div>
    )
}