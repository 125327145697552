import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function LoginFormFooter({ type }) {

    return (
        <div className="flex justify-center py-4">
            {type === "signup" ?
                <div className="text-center text-gray-700 text-xs font-bold">
                    <span>Don't have an account? </span>
                    <Link to="/sign-up" className="cursor-pointer text-prim font-bold outline-none">Signup
                    </Link>
                </div>
                :
                <div className="text-center text-gray-700 text-xs font-bold">
                    <span>Already have an account? </span>
                    <Link to="/sign-in" className="cursor-pointer text-prim font-bold outline-none">Log in
                    </Link>
                </div>
            }
        </div>
    )
}
