import {useSelector, useDispatch} from 'react-redux';
import {getAllRoles} from "../../redux/actions/roles";
import {INCREMENT_COUNT, DECREMENT_COUNT, INCREMENT_BY_AMOUNT_COUNT} from "../../redux/types/count/types";

export default function ReduxTest() {
    const {counter: {count}, roles: {roles}} = useSelector(state => state);
    const dispatch = useDispatch();

    console.log(roles, 'roles')

    return (
        <>
            <div>
                <ul>
                    {roles.map((item, index) => (
                        <li key={index}>{item.type}</li>
                    ))}
                </ul>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <div>
                    <p>{count}</p>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                        <button
                            style={{
                                display: 'block',
                                backgroundColor: 'green',
                                padding: '10px 15px',
                                color: '#fff'
                            }}
                            onClick={() => dispatch({type: INCREMENT_COUNT, payload: 1})}
                        >
                            Add
                        </button>
                        <button style={{
                            display: 'block',
                            backgroundColor: 'red',
                            padding: '10px 15px',
                            color: '#fff',
                            marginLeft: '5px'
                        }}
                                onClick={() => dispatch({type: DECREMENT_COUNT, payload: 1})}
                        >
                            Remove
                        </button>
                        <button style={{
                            display: 'block',
                            backgroundColor: '#1d5dbf',
                            padding: '10px 15px',
                            color: '#fff',
                            marginLeft: '5px'
                        }}
                                onClick={() => dispatch({type: INCREMENT_BY_AMOUNT_COUNT, payload: 30})}
                        >
                            Add By 30
                        </button>
                        <button style={{
                            display: 'block',
                            backgroundColor: '#efbf0f',
                            padding: '10px 15px',
                            color: '#fff',
                            marginLeft: '5px'
                        }}
                                onClick={() => getAllRoles(dispatch)}
                        >
                            Gell All Roles
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
