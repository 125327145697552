import React from 'react';
export default function Item ({title}) {
    
    return (
        <div className="text-gray-700 hover:shadow px-4 py-2 hover:text-red-700 flex items-center justify-between trans cursor-pointer">
            <div className="flex items-center">
                <p className="mr-2">{title}</p>
            </div>
            <div className="w-5 h-5">
                <div className="w-5 h-5 border-2 flex items-center justify-center border-gray-400 rounded-md">
                </div>
            </div>
        </div>
    )
}