import React from 'react';
import { Link } from 'react-router-dom';

export default function Header () {
    return (
        <>
        <div className="w-full justify-end hidden sm:flex md:flex">
            <Link to='/'>
                <button className="px-4 py-2 rounded-md border bg-white">Back to Home</button>
            </Link>
        </div>
        <div className="px-10 mt-4 sm:mt-0 md:mt-0">
            <div className="flex items-center">
                <div className="flex sm:hidden md:hidden">
                    <Link to='/'>
                    </Link>
                </div>
            </div>
        </div>
        </>
    )
}