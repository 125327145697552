import React from 'react';
import { FiBell } from 'react-icons/fi';
import Icon from '../utilities/Icon';
import NavbarTitle from './NavbarTitle';
import PartnerOrCustomer from './PartnerOrCustomer';
export default function PartnerMenu() {
    return (
        <>
            <PartnerOrCustomer route='/sign-in' type="customer" />
            <div className="px-3">
                <Icon icon={FiBell} />
            </div>
            <NavbarTitle route="/admin" title="Rashel" />
        </>
    )
}