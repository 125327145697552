import React from 'react';
import Content from './Content';
export default function TotalAmount() {
    return (
        <>
            <div className="flex items-center justify-between my-6">
                <div>
                    <p className="text-2xl font-bold">Order Details</p>
                </div>
            </div>
            <Content title="Sub Total" value="4.00" />
            <Content title="Discount" value="----" />
            <Content title="Discount" value="£00" />
            <Content title="Service Charges" value="£0.50" />
            <Content title="Total" value="£4.50" />
        </>
    )
}