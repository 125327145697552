import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import UserLayout from './../../../components/layout/UserLayout/index';
import Heading from '../../../components/utilities/Heading';
import Tabs from '../../../components/utilities/Tabs';
import RiderIncomeReport from './RiderIncomeReport';

const RiderIncome = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>FoodApps | Partner Bookings</title>
      </Helmet>
      <UserLayout>
        <Heading>Income Report</Heading>
        <div className="mt-4">
          <div className="mt-4">
            <Tabs variant="sec" tabs={["Today", "Last 7 Days"]} />
          </div>
          <div className="sm:flex md:flex items-center justify-between">
            <div></div>
            <div className="px-2 sm:px-8 md:px-8 mt-2 sm:mt-0 md:mt-0">
              <p className="text-sm font-bold">
                Total: <span className="fotn-thin">£7.70</span>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full pr-4">
          <RiderIncomeReport
            orderId="TES035"
            date="16/08/2021"
            price="45"
          />
        </div>
        {/* <div className="flex flex-wrap items-center mt-4">
                <div className="px-8 py-6">
                    <p className="text-2xl font-thin">No Sales Found!</p>
                </div>
            </div> */}
      </UserLayout>
    </HelmetProvider>
  );
};

export default RiderIncome;
