import axios from "axios";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiBaseUrl, apiVersionPrefix } from "../../config";
import { GET_TERMS_AND_CONDITION_SUCCESS, GET_TERMS_AND_CONDITION_FAILURE, GET_TERMS_AND_CONDITIONS_SUCCESS } from "../types/termsAndCondition/type";

var varToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxMzA4Mzg5MDhmYzAwMTZkNGM1ZmJmNiIsImlhdCI6MTYzMDg2NDQzOSwiZXhwIjoxNjMzNDU2NDM5fQ.TovnvxNo_fDF80yCxd4eNH2Qf25AHDMWubfernHjrn4";


export const getTermsAndCondition = async (dispatch, type) => {
    try {
        const { data: { data } } = await axios.get(`${apiBaseUrl}${apiVersionPrefix}/terms-and-condition/get-by-role?role=${type}`);
        if (data) {
            dispatch({ type: GET_TERMS_AND_CONDITION_SUCCESS, payload: data })
        }
    } catch (err) {
        const { response: { data: { error } } } = err;
        dispatch({ type: GET_TERMS_AND_CONDITION_FAILURE, payload: error });
    }
}


export const getNewTermsAndCondition = createAsyncThunk(
    "superAdmin/getNewTermsAndCondition",
    async (obj, { dispatch, getState, rejectWithValue }) => {
        try {
            const { data: { data } } = await axios.post(`${URL}/api/v1/terms-and-condition/create`, obj, {
                headers: {
                    Authorization: `Bearer ` + varToken
                }
            });
            console.log(data);
            return data;
        } catch (err) {
            const { response: { data: { error } } } = err;
            return rejectWithValue(error);
        }
    }
)

export const getUpdateTermsAndCondition = createAsyncThunk(
    "superAdmin/getUpdateTermsAndCondition",
    async ({ state, _id }, { dispatch, getState, rejectWithValue }) => {
        try {
            const { data: { data } } = await axios.put(`${URL}/api/v1/terms-and-condition/update/by/${_id}`, state, {
                headers: {
                    Authorization: `Bearer ` + varToken
                }
            });
            console.log(data);
            return data;
        } catch (err) {
            const { response: { data: { error } } } = err;
            console.log(error, "e");
            return rejectWithValue(error);
        }
    }
)

export const getAllTermsAndConditions = createAsyncThunk(
    'superAdmin/getAllTermsAndConditions',
    async () => {
        try {
            const data = await (await axios.get(`${URL}/api/v1/terms-and-condition/all`)).data;
            return data;
        } catch (err) {
            return err;
        }
    })

export const getDeleteTermsAndCondition = createAsyncThunk(
    "superAdmin/getDeleteTermsAndCondition",
    async (_id, { dispatch, getState, rejectWithValue }) => {
        try {
            const { data: { data } } = await axios.delete(`${URL}/api/v1/terms-and-condition/delete/by/${_id}`,
                {
                    headers: {
                        Authorization: `Bearer ` + varToken
                    }
                });
            return data;
        } catch (err) {
            const { response: { data: { error } } } = err;
            return rejectWithValue(error);
        }
    }
)
