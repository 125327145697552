import React, { useState } from 'react';
//
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FiShoppingCart } from 'react-icons/fi';
import OutsideClickHandler from 'react-outside-click-handler';
//
import ContactUsButton from '../../../../../../components/navbar/ContactUsButton';
import CustomerMenu from '../../../../../../components/navbar/CustomerMenu';
import PartnerOrCustomer from '../../../../../../components/navbar/PartnerOrCustomer';
import NotificationsButton from '../../../../../../components/utilities/buttons/NotificattionsButton';
import Icon from '../../../../../../components/utilities/Icon';
import { Link } from 'react-router-dom';
import { FiLogIn } from "react-icons/fi";


export default function CustomerNavbar() {
    const [openSubmenu, setOpenSubmenu] = useState(false);
    return (
        <>
            <ContactUsButton icon />
            <PartnerOrCustomer route="/choose-partner" type="partner" />
            <Link to="sign-in">
                <FiLogIn className="inline text-prim" /> Login
            </Link>
            <div className="px-4">
                <div className="flex items-end">
                    <NotificationsButton iconColor="text-prim" />
                    <div className="px-3">
                        <Icon icon={FiShoppingCart} />
                    </div>
                    <div className="relative flex items-center">
                        <div onClick={() => setOpenSubmenu(!openSubmenu)} className="flex">
                            <p className="cursor-pointer text-prim hover:text-red-900 trans">Profile</p>
                            <Icon icon={BsThreeDotsVertical} />
                        </div>
                        {openSubmenu &&
                            <OutsideClickHandler
                                onOutsideClick={() => {
                                    setOpenSubmenu(false)
                                }}
                            >
                                <CustomerMenu />
                            </OutsideClickHandler>

                        }
                    </div>
                </div>
            </div>
        </>
    )
}