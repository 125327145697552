import React from 'react'
import { FiPlus } from 'react-icons/fi'

export default function ProductItem(props) {
    return (
        <div className="sm:flex md:flex w-full border-b justify-between py-4">
            <div>
                <p className="text-lg pb-2 text-black sm:text-gray-700 md:text-gray-700">{props.name}</p>
                <div className="flex items-center">
                    <p className="text-md text-gray-600"></p>
                </div>
            </div>
            <div className="flex items-start justify-between sm:px-6 md:px-6 mt-3 sm:mt-0 md:mt-0">
                <p className="text-md mr-8 w-32 text-gray-600 mt-2">
                    <b className="text-gray-800"> £{props.price}</b>
                </p>
                <div onClick={() => props.onClick(props.name)} className="flex items-center mt-2">
                    <div className="cursor-pointer bg-gray-600 hover:bg-red-700 rounded-full overflow-hidden flex items-center">
                        <FiPlus className="trans text-white p-1 text-4xl" />
                    </div>
                </div>
            </div>
        </div>
    )
}
