import React from 'react';

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

// Routes
import userRoutes from './UserRoutes';


// Components
import RouteWithSubRoutes from './RouteWithSubRoutes';
import ReduxTest from '../pages/test/ReduxTest';

// import CompTest from '../pages/test/CompTest';

const MasterRoute = () => {
    return (
        <Router>
            <Switch>
                {userRoutes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />)}
                <Route path='/redux-test' exact component={ReduxTest} />
            </Switch>
        </Router>
    )
}

export default MasterRoute;
