
// import { createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";

// const URL = process.env.REACT_APP_API_BASE_URL;
// console.log(URL, "url");
// var varToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxMzA4Mzg5MDhmYzAwMTZkNGM1ZmJmNiIsImlhdCI6MTYzMDk0NDM2MywiZXhwIjoxNjMzNTM2MzYzfQ.v-FsyxNY1SjeC3-8DcczZ190Sw_9n6kCpDxww2kvp1Q";

// export const getAllRoles = createAsyncThunk(
//     "superAdmin/getAllRoles",
//     async({ rejectWithValue, getState})=>{
//       try {
//         const data = await axios.get(`${URL}/api/v1/role/get-all-roles`, {
//             headers: {
//                 Authorization: `Bearer ` + varToken
//             }
//         });
//         console.log(data);
//         // return data;
//       } catch (err) {
//         const {response:{data:{error}}}=err;
//         console.log(error, "e");
//         return rejectWithValue(error);
//       }
//     }
//   )

import {GET_ALL_ROLES} from "../types/roles/types";
import {apiBaseUrl, apiVersionPrefix} from "../../config";
import axios from "axios";

/* Get All Roles */
export const getAllRoles = async (dispatch) => {
    try {
        const config = {
            method: 'get',
            url: `${apiBaseUrl}${apiVersionPrefix}/role/get-all-roles`,
            headers: {
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxMzYzNWUwNTdiMzQwNDYyOTM1NTJmMCIsImlhdCI6MTYzMTAzNjgwMiwiZXhwIjoxNjMzNjI4ODAyfQ.kPP8r_djonqnFOMrSmI2V54FtasQi8Kjec4cSxAG6nE',
            }
        };
        const response = await axios(config);

        if (response.data) {
            dispatch({type: GET_ALL_ROLES, payload: response.data.data});
        }
    } catch (error) {
        console.log(error.response);
    }
};

