import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../utilities/buttons/Button';
export default function IncogNav(props) {
    return (
        <div className="px-4">
            <Link to={props.route}>
                {props.img ?
                    <div style={props.containerStyle} className={`flex items-center ${props.containerClasses}`}>
                        {!props.scrolled &&
                            <img alt="foodapps.uk" className="object-cover w-5 h-5" src={props.img} />
                        }
                        <div href="#" className="pl-2 cursor-pointer text-md text-prim-hover trans">{props.title}</div>
                    </div>
                :
                    <div style={props.containerStyle} className={`outline-none flex items-center ${props.containerClasses}`}>
                        {!props.scrolled ?
                            <>
                                <props.icon className="text-red-600" />
                                <p className="pl-2">{props.title}</p>
                            </>
                        :
                            <Button style={{paddingLeft: "1.5rem", paddingRight: "1.5rem"}}>Customer</Button>
                        }
                        
                    </div>
                }
            </Link>
        </div>
    )
}