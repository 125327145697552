import React from 'react'

export default function Field(props) {
    return (
        <input
            onBlur={props.onBlur}
            style={props.style}
            type={props.type}
            name={props.name}
            id={props.id}
            onChange={props.onChange}
            value={props.value}
            placeholder={props.placeholder}
            className={`w-full outline-none p-1 text-black border border-red-700 rounded ${props.classes}`}
        />
    )
}
