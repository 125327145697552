
import { createSlice } from "@reduxjs/toolkit";
import { getAllRoles } from "../actions/roles";

const initialState = {
    allRoles: [],
    status: null,
    error: null,
}

const RolesSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        toggleError: state => {
            state.errorState = !state.errorState;
        }
    },
    extraReducers: {
        [getAllRoles.pending]: (state, action) => {
            state.error = null;
            state.status = "loading";
            state.allRoles = [];
            // state.errorState=false
        },
        [getAllRoles.fulfilled]: (state, action) => {
            state.error = null;
            state.allRoles = action.payload;
            state.status = "success";
            // state.errorState=false;
        },
        [getAllRoles.rejected]: (state, action) => {
            state.error = action.payload;
            state.status = 'failed';
            state.allRoles = [];
            // state.errorState=true;
        },
    }
})

export const {toggleError} = RolesSlice.actions;

export default RolesSlice.reducer;          

// import {GET_ALL_ROLES} from "../types/roles/types";

// let initialState = {
//     roles: []
// };

// export default function restaurantReducer(currentState = initialState, action) {
//     switch (action.type) {
//         case GET_ALL_ROLES:
//             return {...currentState, roles: action.payload};
//         default:
//             return currentState;
//     }
// };


