import React from 'react'

export default function ProfileInfoCard(props) {
    return (
        <div style={props.containerStyle} className={`w-full sm:w-1/3 md:w-1/3 mr-8 border mt-8 rounded-md p-2 sm:p-4 md:p-4 border-red-500 flex-col ${props.containerClasses}`}>
            <p style={props.titleStyle} className={`py-3 text-gray-700 ${props.titleClasses}`}>{props.title}</p>
            {props.value?.map((info, i) =>
                <p
                    key={i}
                    style={props.style}
                    className={`pb-3 text-gray-700
                    ${props.classes}`}
                >{info}</p>
                )}
        </div>
    )
}
