import {
  GET_DRIVER_SUCCESS,
  GET_DRIVER_FAILURE,
  GET_OTP_SUCCESS,
  GET_OTP_FAILURE,
  GET_NEARBY_RESTAURANT_SUCCESS,
  GET_NEARBY_RESTAURANT_FAILURE
} from "../types/driver/types";
import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";
import { setCookie } from "../../components/utilities/cookie/cookie";

const URL = "http://localhost:5000";

export const getDriverLogin = async (dispatch, formData) => {
  try {
    const {
      data: { data, token },
    } = await axios.post(`${URL}/api/v1/driver/signin`, formData);
    console.log('data', data);
    if (data) {
      setCookie("token", token, 360000);
      localStorage.setItem("driver", JSON.stringify(data));
      dispatch({ type: GET_DRIVER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    // console.log(error)
    dispatch({ type: GET_DRIVER_FAILURE, payload: error });
  }
};

export const getDriverSignup = async (dispatch, formData) => {
  try {
    const {
      data: { data, token },
    } = await axios.post(`${URL}/api/v1/driver/signup`, formData);
    if (data) {
      setCookie("token", token);
      localStorage.setItem("driver", JSON.stringify(data));
      dispatch({ type: GET_DRIVER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_DRIVER_FAILURE, payload: error });
  }
};

export const getNearbyRestaurants = async (dispatch, postCode, token) => {
  try {
    const { data: { data } } = await axios.get(`${apiBaseUrl}${apiVersionPrefix}/driver/search-nearby-restaurant/${postCode}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (data) {
      dispatch({ type: GET_NEARBY_RESTAURANT_SUCCESS, payload: data })
    }
  } catch (err) {

    const { response: { data: { error } } } = err;
    console.log(error)
    dispatch({ type: GET_NEARBY_RESTAURANT_FAILURE, payload: error })
  }
}


/*************************** */

export const getLoggedRiderInfo = async (dispatch, token) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/driver/get-driver-own`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_DRIVER_SUCCESS, payload: data });
    }
  } catch (err) {
    console.log(err);
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_DRIVER_FAILURE, payload: error });
  }
};

export const createDriverAddress = async (dispatch, obj) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${URL}/api/v1/address/create-driver-address`,
      obj.formData,
      {
        headers: {
          Authorization: `Bearer ${obj.token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_DRIVER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_DRIVER_FAILURE, payload: error });
  }
};

export const updateDriverAddress = async (
  dispatch,
  { token, id, formData }
) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${URL}/api/v1/address/update-driver-address/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data) {
      dispatch({ type: GET_DRIVER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_DRIVER_FAILURE, payload: error });
  }
};

export const updateDriver = async (dispatch, { token, formData }) => {
  try {
    const {
      data: { data },
    } = await axios.put(`${URL}/api/v1/driver/update-driver-own`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (data) {
      dispatch({ type: GET_DRIVER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_DRIVER_FAILURE, payload: error });
  }
};

export const getDriverByPhone = async (dispatch, formData) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${URL}/api/v1/driver/get-driver-by-phone`,
      formData
    );
    localStorage.setItem("driver", JSON.stringify(data));
    if (data) {
      dispatch({ type: GET_DRIVER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_DRIVER_FAILURE, payload: error });
  }
};

export const resetNewPassword = async (dispatch, formData) => {
  try {
    const {
      data: { data },
    } = await axios.put(`${URL}/api/v1/driver/set-new-password`, formData);

    if (data) {
      dispatch({ type: GET_DRIVER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_DRIVER_FAILURE, payload: error });
  }
};

export const guestDriverLogin = async (dispatch, formData) => {
  try {
    const {
      data: { data, token },
    } = await axios.post(`${URL}/api/v1/guest/guest-signup`, formData);
    if (data) {
      setCookie("token", token, 360000);
      localStorage.setItem("driver", JSON.stringify(data));
      dispatch({ type: GET_DRIVER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    // console.log(error)
    dispatch({ type: GET_DRIVER_FAILURE, payload: error });
  }
};

export const checkGuestMobile = async (dispatch, formData) => {
  try {
    const {
      data: { data },
    } = await axios.post(`${URL}/api/v1/guest/check-guest-mobile`, formData);
    if (data) {
      dispatch({ type: GET_OTP_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    // console.log(error)
    dispatch({ type: GET_OTP_FAILURE, payload: error });
  }
};

// export const getAllFavourites = async (dispatch, id, token) => {
//   try {
//     const {
//       data: { data },
//     } = await axios.get(
//       `${URL}/api/v1/driver/get-favourite-restaurant/${id}`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     if (data) {
//       dispatch({ type: GET_DRIVER_SUCCESS, payload: data });
//     }
//   } catch (err) {
//     const {
//       response: {
//         data: { error },
//       },
//     } = err;
//     // console.log(error)
//     dispatch({ type: GET_DRIVER_FAILURE, payload: error });
//   }
// };
