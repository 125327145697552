import {
  GET_CAROUSEL_SUCCESS,
  GET_ALL_CAROUSEL_SUCCESS,
  GET_CAROUSEL_FAILURE,
  DELETE_CAROUSEL_SUCCESS,
} from "../types/carousel/type";

const initialState = {
  carousel: {},
  carousels: [],
  status: null,
  error: "",
  loading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAROUSEL_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        carousel: action.payload,
        error: "",
      };
    case GET_ALL_CAROUSEL_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        carousels: action.payload,
        error: "",
      };
    case DELETE_CAROUSEL_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        message: action.payload,
        error: "",
      };

    case GET_CAROUSEL_FAILURE:
      return {
        ...state,
        loading: false,
        status: "Failed",
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
