import React from 'react';

import Cart from '../../../components/cart';
import Categories from './categories';
import Products from './products';
import Navbar from '../Home/Navbar';
import OrderHeader from './OrderHeader';

export default function Order() {
    return (
        <div className="bg-nat">
            <Navbar />
            <OrderHeader />
            <div className="sm:flex md:flex px-4 bg-gray pb-8">
                <Categories categories={["Curry", "Chicken", "Onion", "Drinks"]} />
                <Products />
                <div className="hidden md:block w-full sm:w-3/12 md:w-3/12 mt-10 sm:mt-0 md:mt-0 shadow-lg">
                    <Cart />
                </div>
            </div>
        </div>
    )
}