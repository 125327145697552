import React from 'react'

import { useState } from 'react'

import OrderOptionsPopup from './OrderOptionsPopup'
import ProductItem from './ProductItem'

export default function Products() {
    const [openModal, setOpenModal] = useState(false)
    return (
        <div className="w-full sm:w-7/12 md:w-7/12 h-full mr-2 bg-white shadow-lg rounded-lg">
            <div className="px-5 pb-5 pt-3 mb-6 md:mb-0">
                <div className="w-full" style={{ minHeight: "112px" }}>
                    <p className="text-xl md:uppercase font-bold text-center border-b pb-3 md:pb-6 text-gray-700">
                        Curry
                    </p>
                    <ProductItem 
                        name="Fish Curry"
                        price="6"
                        onClick={() => setOpenModal(true)}
                        />
                    <ProductItem
                        name="Onion Fry"
                        price="4"
                        onClick={() => setOpenModal(true)}
                    />
                </div>
            </div>
            <OrderOptionsPopup openModal={openModal} setOpenModal={setOpenModal} />
        </div>
    )
}
