import React from "react";
//
import { Helmet, HelmetProvider } from "react-helmet-async";
//
import Booking from "../../../components/booking";
import UserLayout from "../../../components/layout/UserLayout";
import AdminLayout from '../../../components/layout/AdminLayout';
import Button from "../../../components/utilities/buttons/Button";
import Heading from "../../../components/utilities/Heading";
import { useState } from "react";
import Tabs from '../../../components/utilities/Tabs';
import MyOrder from '../../../components/myOrder';

// const booking = {
//   name: "",
//   bookingFor: "",
//   contact: "",
// };
const order1 = {
  orderId: "TES063",
  type: "Collected",
  status: "21 / Aug - 08:28 AM",
  collection: "30 Min",
  paymentMethod: "card",
  itemPrice: "65",
  total: "78"
}
export default function UserMyBooking() {
  const [newBooking, setNewBooking] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    guestNo: "",
    bookingFor: "",
    reservationDate: "",
    contactNo: "",
    time: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>FoodApps | Partner In-House Orders</title>
      </Helmet>
      <UserLayout>
        <Heading>In-House Orders</Heading>
        <div className="mt-4">
          <div className="px-6 flex justify-between">
            <Tabs variant="prim" tabs={["All Orders", "Dine-in"]} />
            <div className="flex items-center">
              <Button variant="sm">New Order</Button>
            </div>
          </div>
          <div className="px-4 flex flex-wrap w-full items-center mt-4">
            <div className="w-full md:w-3/3 p-2">
              <MyOrder order={order1} />
            </div>
          </div>
        </div>
        {/* <div className="flex flex-wrap items-center mt-4">
                <div className="px-8 py-6">
                    <p className="text-2xl font-thin">No Orders Yet!</p>
                </div>
            </div> */}
      </UserLayout>
    </HelmetProvider>
  );
}
