import React from 'react';
import { FiUser } from 'react-icons/fi';
import { Link } from 'react-router-dom';
export default function SwitchToCustomer(props) {
    return (
        <div className="px-4 pr-6">
            <Link className="flex items-center" to={props.route}>
                {props.type==="partner" &&
                    <div className="flex items-center">
                        <img alt="foodapps partner" className="object-cover w-5 h-5" src="https://foodapps.uk/images/icons/partner.png" />
                        <div className="pl-2 cursor-pointer text-md font-bold text-prim-hover trans">
                            <span className="text-sm mr-1 font-normal">Become our </span> Partner
                        </div>
                    </div>
                    }
                {props.type==="customer" &&
                    <>
                        <FiUser className="text-red-600" />
                        <div className="cursor-pointer text-md font-bold text-prim-hover trans">
                            <span className="text-sm mx-1 font-normal">Switch to </span> Customer
                        </div>
                    </>
                }
            </Link>
        </div>
    )
}