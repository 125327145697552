import React from 'react';
import MobileNavbar from '../MobileNavbar';
import NormalNavbarLogo from './NormalNavbarLogo';
import NormalNavbarMenu from './NormalNavbarMenu';

export default function NormalNavbar() {
    return (
        <>
        <div className="hidden sm:hidden md:flex lg:flex px-10 items z-100 bg-nat w-full shadowlg py-2">
            <div className="flex w-full justify-between">
                <NormalNavbarLogo />
                <NormalNavbarMenu type="customer" />
                {/* <div className="flex items-center pl-10">
                    <div className="px-4 pr-6">
                        <Link href="/partner">
                            <div className="flex items-center">
                                <FiUser className="text-red-600"></FiUser>
                                <div className="cursor-pointer text-md font-bold text-prim-hover trans">
                                    <span className="text-sm mx-1 font-normal">Switch to </span>
                                    <span> Customer</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="px-3">
                        <div className="relative">
                            <Link href="/super-admin/notifications">
                                <>
                                <div className="bg-prim w-3 h-3 flex items-center justify-center absolute p-2 top-0 right-0 rounded-full -mt-2 -mr-2 text-white text-xs">1</div>
                                <FiBell className="text-prim hover:text-black trans cursor-pointer text-xl" />
                                </>
                            </Link>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
        <MobileNavbar type="customer" />
        </>
    )
}