import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import UserLayout from "../../../components/layout/UserLayout";
import Heading from "../../../components/utilities/Heading";
import Tabs from "../../../components/utilities/Tabs";
import PartnerMenu from "./../../../components/navbar/PartnerMenu";
import NotificationCard from '../../../components/utilities/cards/NotificationCard';

const RiderInvoice = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>FoodApps | Partner In-House Orders</title>
      </Helmet>
      <UserLayout>
        <Heading>Invoice</Heading>
        <div className="mt-4">
          <div className="px-6 flex justify-between">
            <Tabs variant="prim" tabs={["Week", "Month"]} />
          </div>
          <div className="px-4 flex flex-wrap w-full items-center mt-4">
            <div className="w-full">
              <NotificationCard
                title="Get 10 off your first order"
                desc=""
                dateTime="Expire on 21 Marcg 2021"
              />
              <NotificationCard
                title="Get 10 off your first order"
                desc=""
                dateTime="Expire on 21 Marcg 2021"
              />
              <NotificationCard
                title="Get 10 off your first order"
                desc=""
                dateTime="Expire on 21 Marcg 2021"
              />
            </div>
          </div>
        </div>
        {/* <div className="flex flex-wrap items-center mt-4">
                <div className="px-8 py-6">
                    <p className="text-2xl font-thin">No Orders Yet!</p>
                </div>
            </div> */}
      </UserLayout>
    </HelmetProvider>
  );
};

export default RiderInvoice;
