import {
  GET_CATEGORY_FAILURE,
  GET_CATEGORY_SUCCESS,
  GET_ALL_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
} from "../types/category/type";

const initialState = {
  category: {},
  categories: [],
  status: null,
  error: "",
  loading: true,
  message: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        category: action.payload,
        error: "",
      };
    case GET_ALL_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        categories: action.payload.categories,
        category: action.payload.category,
        error: "",
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        message: action.payload,
        error: "",
      };

    case GET_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        status: "Failed",
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
