import React from 'react';

import CheckoutHeader from '../../../components/checkout/CheckoutHeader';
import CheckoutTableNumber from '../../../components/checkout/TableNumber';
import CheckoutCard from '../../../components/checkout/CheckoutCard';
import YourOrder from './YourOrder';
export default function Checkout() {
    return (
        <div className="bg-nat">
            <div>

            </div>
            <div className="py-16 sm:px-32 md:px-32">
                <div className="w-full sm:flex md:flex">
                    <div className="w-full sm:w-2/3 md:w-2/3 h-full p-6">
                        <div className="w-full h-full">
                            <div className=" flex items-center">
                                <div className="w-full text-center">
                                    <h1 style={{fontSize: "2em"}} className="mb-4">Dine-In</h1>
                                </div>
                            </div>
                            <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6">
                                <CheckoutHeader title="Table Number" editable={true} />
                                <CheckoutTableNumber />
                            </div>
                            <CheckoutCard />
                        </div>
                    </div>
                    <div className="w-full sm:w-1/3 md:w-1/3 h-full p-6">
                        <YourOrder />
                    </div>
                </div>
            </div>
        </div>
    )
}