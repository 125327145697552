import React from 'react';

import { FiX } from 'react-icons/fi';
import Heading from '../../../../../components/utilities/Heading';

export default function Header ({ setOpenModal, heading }) {
    return (
        <div className="flex items-center justify-between">
            <Heading classes="text-gray-700">{heading}</Heading>
            <FiX onClick={() => setOpenModal(false)} className="cursor-pointer text-gray-600 hover:text-black trans text-2xl" />
        </div>
    )
}