import React from 'react'
export default function RoundIcon(props) {
    return (
        <div style={props.containerStyle} className={`rounded-full w-10 h-10 flex items-center justify-center bg-prim text-white ${props.containerClasses}`}>
            {props.img? 
                <img style={{...props.style, filter: "invert(1)"}} className={`object-cover w-5 h-5 image-invert ${props.classes}`} src={props.img} alt="book your table" />
                :
            props.icon?
                <props.icon style={props.style} className={`${props.classes}`} />
                :
                <p style={props.style} className={`${props.classes}`}>{props.text}</p>
            }
        </div>
    )
}
