import React from 'react'

export default function PaymentOptionCard(props) {
    return (
        <div className="sm:flex md:flex flex-wrap items-center mt-4">
            <div className="sm:flex md:flex flex-wrap items-center w-full">
                {props.options?.map((op, i) => 
                <div key={i} className="p-2 px-3 w-full sm:w-1/2 md:w-1/2">
                    <div style={props.containerStyle} className={`flex items-center justify-center text-sec rounded p-4 cursor-pointer border border-red-700 ${props.ContainerClasses}`}>
                        <p style={props.style} className={`text-4xl font-thin italic ${props.classes}`}>{op}</p>
                    </div>
                </div>)}
            </div>
        </div>
    )
}
