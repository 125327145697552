import {GET_TERMS_AND_CONDITION_SUCCESS,GET_TERMS_AND_CONDITION_FAILURE,GET_TERMS_AND_CONDITIONS_SUCCESS} from "../types/termsAndCondition/type";


const initialState = {
    termsAndCondition:{},
    termsAndConditions:[],
    status: null,
    error: null,
    loading:true
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TERMS_AND_CONDITION_SUCCESS:
      return {
        ...state,
        loading: false,
        status:"success",
        termsAndCondition: action.payload
      };
    case GET_TERMS_AND_CONDITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        status:"success",
        termsAndConditions: action.payload
      };
    case GET_TERMS_AND_CONDITION_FAILURE:
      return {
        ...state,
        loading: false,
        status:"Failed",
        error: action.payload
      };
    default:
      return state;
  }
};

export default reducer;






// const termsOfConditionsSlice = createSlice({
//     name: 'termsOfConditions',
//     initialState,
//     extraReducers: {
        
//         [getTermsAndCondition.pending]:(state,action)=>{
//             state.error=null;
//             state.status="loading";
//             state.termsAndCondition={};
//           },
//           [getTermsAndCondition.fulfilled]:(state,action)=>{
//             state.error=null;
//             state.termsAndCondition=action.payload;
//             state.status="success";
            
//           },
//           [getTermsAndCondition.rejected]:(state,action)=>{
//             state.error=action.payload;
//             state.status='Failed';
//             state.termsAndCondition={};
//           },
//         // login reducers
//         [getNewTermsAndCondition.pending]: (state, action) => {
//             state.error = null;
//             state.status = "loading";
//             state.singleTermsAndCondition = {};
//             // state.errorState=false
//         },
//         [getNewTermsAndCondition.fulfilled]: (state, action) => {
//             state.error = null;
//             state.singleTermsAndCondition = action.payload;
//             state.status = "success";
//             // state.errorState=false;
//         },
//         [getNewTermsAndCondition.rejected]: (state, action) => {
//             state.error = action.payload;
//             state.status = 'Failed';
//             state.singleTermsAndCondition = {};
//             // state.errorState=true;
//         },
//         // Update Terms And Conditions
//         [getUpdateTermsAndCondition.pending]: (state, action) => {
//             state.error = null;
//             state.status = "loading";
//             state.singleTermsAndCondition = {};
//             // state.errorState=false
//         },
//         [getUpdateTermsAndCondition.fulfilled]: (state, action) => {
//             state.error = null;
//             state.singleTermsAndCondition = action.payload;
//             state.status = "success";
//             // state.errorState=false;
//         },
//         [getUpdateTermsAndCondition.rejected]: (state, action) => {
//             state.error = action.payload;
//             state.status = 'Failed';
//             state.singleTermsAndCondition = [];
//             // state.errorState=true;
//         },
//         [getAllTermsAndConditions.pending]: (state, action) => {
//             state.error = null;
//             state.status = "loading";
//             state.allTermsAndConditions = [];
//             // state.errorState=false
//         },
//         // Get all Terms of Conditions
//         [getAllTermsAndConditions.fulfilled]: (state, action) => {
//             state.error = null;
//             state.allTermsAndConditions = action.payload;
//             state.status = "success";
//             // state.errorState=false;
//         },
//         [getAllTermsAndConditions.rejected]: (state, action) => {
//             state.error = action.payload;
//             state.status = 'failed';
//             state.allTermsAndConditions = [];
//             // state.errorState=true;
//         },
//         [getAllTermsAndConditions.pending]: (state, action) => {
//             state.error = null;
//             state.status = "loading";
//             state.allTermsAndConditions = [];
//             // state.errorState=false
//         },
//         // Get all Terms of Conditions
//         [getAllTermsAndConditions.fulfilled]: (state, action) => {
//             state.error = null;
//             state.allTermsAndConditions = action.payload;
//             state.status = "success";
//             // state.errorState=false;
//         },
//         [getAllTermsAndConditions.rejected]: (state, action) => {
//             state.error = action.payload;
//             state.status = 'failed';
//             // state.errorState=true;
//         },
//         [getDeleteTermsAndCondition.pending]: (state, action) => {
//             state.error = null;
//             state.status = "loading";
//             // state.errorState=false
//         },
//         // Get all Terms of Conditions
//         [getDeleteTermsAndCondition.fulfilled]: (state, action) => {
//             state.error = null;
//             // state.allTermsAndConditions = action.payload;
//             state.status = "success";
//             // state.errorState=false;
//         },
//         [getDeleteTermsAndCondition.rejected]: (state, action) => {
//             state.error = action.payload;
//             state.status = 'failed';
//             // state.errorState=true;
//         },
//     }
// })


// export const {toggleError} = termsOfConditionsSlice.actions;

// export default termsOfConditionsSlice.reducer;
