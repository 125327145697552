import React from "react";
import PaymentInfoContent from "./PaymentInfoContent";

const EditWallet = () => {
  return (
    <div className="mt-5 border-b-2 pb-4">
      <PaymentInfoContent
        title="Name"
        subTitle="(on the bank account)"
        placeholder=""
      />
      <PaymentInfoContent title="Bank Name" subTitle="" placeholder="" />
      <PaymentInfoContent title="Sort Code" subTitle="" placeholder="" />
      <PaymentInfoContent title="Account Number" subTitle="" placeholder="" />
      <div className="w-full mt-2 flex flex-wrap justify-end items-end">
        <button className="bg-prim rounded px-5 py-3 text-white">Submit</button>
      </div>
    </div>
  );
};

export default EditWallet;
