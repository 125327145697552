import {
  GET_RESTAURANT_INFO_FAILURE,
  GET_RESTAURANT_INFO_SUCCESS,
  GET_ALL_RESTAURANTS_INFO_SUCCESS,
} from "../types/restaurantInfo/type";

const initialState = {
  restaurantInfo: {},
  restaurants: [],
  status: null,
  error: null,
  errorState: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESTAURANT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        restaurantInfo: action.payload,
      };
    case GET_ALL_RESTAURANTS_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        restaurants: action.payload,
      };

    case GET_RESTAURANT_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        status: "Failed",
        restaurantInfo: {},
        restaurants: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
