import React from 'react'
import { FiShoppingBag } from 'react-icons/fi'

export default function CartHeader(props) {
    return (
        <div className="w-full px-5 pb-4 pt-4 border-b text-gray-800 flex items-center">
            <FiShoppingBag className="text-2xl mr-3" />
            <p className="text-xl font-semibold uppercase">{props.count} items</p>
        </div>
    )
}
