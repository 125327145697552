import React from 'react';
import { Link } from 'react-router-dom';
export default function NavbarTitle(props) {
    return (
        <div className="flex items-end">
            <Link to={props.route}>
                <div className="flex items-center px-3">
                    <p className="text-prim hover:text-red-900 trans">{props.title}</p>
                </div>
            </Link>
        </div>
    )
}