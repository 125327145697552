import React, { useState } from 'react'
import { FiEdit2 } from 'react-icons/fi'

export default function Editable(props) {
    const [editable, setEditable] = useState(false)
    return (
        <p onClick={() => {
            props.onClick(!editable)
            setEditable(!editable)
        }} style={props.containerStyle} className={`cursor-pointer hover:text-red-900 trans font-semibold text-prim text-xs sm:text-sm md:text-sm flex items-center ${props.containerClasses}`}>
            <FiEdit2 style={props.iconsStyle} className={`text-2xl px-1 ${props.iconClasses}`} />
            <span style={props.textStyle} className={`${props.textClasses}`}>Edit</span>
        </p>
    )
}
