import React, { useState } from "react";
import { FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import BookingStatus from "../booking/bookingHeader/BookingStatus";
import Icon from "../utilities/Icon";
import MyOrderInfo from "./MyOrderInfo";
import OrderSummary from "./../../pages/user/UserMyOrder/OrderSummary";

export default function MyOrder(props) {
  const { order } = props;

  return (
    <div className="w-full sm:w-1/3 md:w-1/3 p-2">
      <div
        style={props.containerStyle}
        className={`rounded-lg border border-red-700 p-2 px-4 ${props.containerClasses}`}
      >
        <div className="w-full flex justify-end">
          <Icon icon={FiX} />
        </div>
        <Link to={`/order-summary/${order._id}`}>
          <div className="py-2 flex text-xs items-center justify-between">
            <p className="text-sm font-bold">{order.orderNumber}</p>
            <BookingStatus
              status={order.orderStatus}
              containerClasses="text-red-700 bg-orange-300 px-3"
            />
          </div>
          <MyOrderInfo title="Collection" value={order.orderType} />
          <MyOrderInfo title="PaymentMethod" value={order.paymentMethod} />
          <MyOrderInfo
            title="Item Price"
            value={order.totalPrice}
            containerClasses="border-b-2 border-gray-400"
          />
          <MyOrderInfo title="Total" value={order.totalPrice} />
        </Link>
      </div>
    </div>
  );
}
