import React, { useState } from 'react'

import SpecialOfferPopup from './SpecialOfferPopup'

export default function Categories(props) {
    const [selectedCate, setSelectedCate] = useState(props.categories[0])
    const [openModal, setOpenModal] = useState(false)

    return (
        <div className="w-full sm:w-2/12 md:w-2/12 h-full mr-2 bg-white shadow-lg rounded-lg">
            <div className="text-center">
                <p className="text-xl font-bold border-b py-3 md:py-6 text-gray-700">CATEGORIES</p>
            </div>
            <div className="hidden md:flex flex-wrap m-2 py-4">
                { props.categories?.map( (cate, i) => 
                   <span
                        key={i}
                        onClick={() => setSelectedCate(cate)}
                        className={`${selectedCate === cate? "bg-prim": "bg-gray-700"} w-full hover:bg-red-700 text-white py-1 px-4 cursor-pointer rounded-lg m-1 text-lg`}
                   >
                       {cate}
                    </span>
                )}
                <span onClick={() => setOpenModal(true)} className="bg-gray-700 w-full hover:bg-red-700 text-white py-1 px-4 cursor-pointer rounded-lg m-1 text-lg">
                    Special Offers  
                </span>
            </div>
            <div className="flex md:hidden scrollBar overflow-x-scroll m-2 pb-2">
                { props.categories?.map((cate, i) =>  
                    <div
                        key={i}
                        onClick={() => setSelectedCate(cate)}
                        className={`${selectedCate === cate? "bg-prim": "bg-gray-700"} whitespace-no-wrap text-lg bg-gray-700 hover:bg-red-700 text-white py-1 px-4 cursor-pointer rounded-lg m-1`}
                    >
                        {cate}
                    </div>
                )}

            </div>
            <SpecialOfferPopup openModal={openModal} setOpenModal={setOpenModal} />
        </div>
    )
}
