import React from 'react'

export default function MyOrderInfo(props) {
    return (
        <div style={props.containerStyle} className={`py-2 flex text-xs items-center justify-between ${props.containerClasses}`}>
            <p style={props.style} className={`font-bold ${props.classes}`}>{props.title}</p>
            <p style={props.style} className={`font-bold ${props.classes}`}>{props.value}</p>
        </div>
    )
}
