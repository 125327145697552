
import { FiShoppingBag, FiTruck } from 'react-icons/fi';
import Qr from '../../../../components/Qr';
export default function OrderHeader({ selectedOrder }) {
    // const orderType = ["Home Delivery", "Collection", "Dine-In", "Book a Table"]
    // const [selectedType, setSelectedType] = useState(orderType[0]);
    // const [openDrop, setOpenDrop] = useState(false);
    // useEffect(() => {
    //     console.log(selectedOrder, "one")
    // })
    return (
        <div className="">
            <div className="overflow-hidden w-full bg-gray">
                <div className="relative order-banner h-56 overflow-hidden mt-16 sm:mt-0 md:mt-0">
                <img className="w-full h-full object-cover" src="https://api.foodapps.uk/public/image-1614610645096.jpg" alt="Red Spicy" />
                <div className="absolute bottom-0 left-0 w-24 h-24 mx-2 p-2 rounded-full bg-gray shadow-full  ml-10 sm:ml-20 md:ml-20 mb-2">
                   <div className="w-full h-full rounded-full bg-white overflow-hidden">
                        <img className="w-full h-full object-cover" src="https://api.foodapps.uk/public/image-1615713897824.png" alt="Red Spicy" />
                    </div>
                </div>
                <Qr containerClasses="absolute bottom-0 right-0 mx-2" />
        
                </div>
                <div className="mb-2 w-full bg-white py-3 px-6">
                    <div className="sm:flex md:flex items-center">
                        <div className="w-full sm:w-5/12 md:w-5/12 border-b sm:border-none md:border-none pb-2 sm:pb-0 md:pb-0">
                            <div className="flex items-center">
                                <div className="w-full px-4 truncate">
                                    <div className="flex items-center">
                                        <p className="text-lg sm:text-3xl md:text-3xl font-semibold text-gray-800 truncate">{selectedOrder?.name}</p>
                                        <p className="px-4 text-center text-2xl text-red-700">{selectedOrder?.discount}%<span className="text-xl">{selectedOrder?.discountType}</span></p>
                                    </div>
                                </div>
                            </div>
                            <p className="text-sm text-gray-600 mt-1 truncate">{selectedOrder?.address}, {selectedOrder?.postCode}, {selectedOrder?.city}</p>
                            <p className="mt-2 w-full md:ml-10 text-xs text-gray-600">{selectedOrder?.openingTime} to {selectedOrder?.closingTime}</p>
                        </div>
                        <div className="w-full sm:w-4/12 md:w-4/12 border-b sm:border-none md:border-none pb-2 sm:pb-0 md:pb-0">
                            <div className="flex items-center">
                                <div className="w-full md:w-5/6 px-4 truncate">
                                    <div className="w-full text-red-700 flex flex-wrap items-center">
                                        <div className="md:mx-6 flex w-full items-center md:justify-center">
                                            <div className="flex items-center">
                                                {/* {
                                            selectedOrder?.speciality.map(item =>
                                                <div className="flex items-center mx-2">
                                                    <FiStar className ="text-yellow-600" />
                                                    <p className="text-sm text-gray-700 mt-1 ml-1">{item}</p>
                                                </div>)
                                                } */}
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-center w-full mt-2">
                                        <FiShoppingBag className="text-prim" />

                                        <p className="px-2 text-xs font-bold">{selectedOrder?.pickupTime} Mins,</p>
                                        <FiTruck className="text-prim" />
                                        <p className="px-2 text-xs font-bold">Free, {parseFloat(selectedOrder?.deliveryTime.toString())} min, {selectedOrder?.areaLimit}, Min £{selectedOrder?.minOrder}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <OrderTypeDropdown /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

