import React from 'react';
import NormalNavbar from '../../pages/user/Home/Navbar/NormalNavbar';

export default function UserDriverLayout ( props, {children} ) {

    return (
        <div className="bg-nat">
            <div className="fixed top-0 w-full z-40">
                <NormalNavbar />
            </div>
            <div className="sm:px-16 md:px-16 pt-16 sm:pt-32 md:pt-32">
                <div className="w-full flex min-h-screen">
                    <props.sidebar />
                    <div className="w-10/12 h-full bg-white p-2 sm:p-6 md:p-6 mx-2 sm:mx-12 md:mx-12 rounded-lg shadow-md">
                        <div className="w-full pl-1 sm:pl-4 md:pl-4 mt-6">  
                        {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
