import {
  GET_ADDRESS_FAILURE,
  GET_ADDRESS_SUCCESS,
} from "../types/address/type";

const initialState = {
  address: {},
  status: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        address: action.payload,
      };

    case GET_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        status: "Failed",
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
