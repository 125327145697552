import React from 'react'
import CountControl from '../utilities/CountControl'

export default function CartItem() {
    return (
        <div className="mt-2 rounded-lg bg-white p-3 px-6 border-b flex items-center">
            <CountControl />
            <div className="flex-grow px-4">
                <p className="text-md text-gray-800">Samosa</p>
            </div>
            <div className="px-4">
                <p className="text-md text-gray-700 font-semibold">£5.00</p>
            </div>
        </div>
    )
}
