import React from 'react';
import { Link } from 'react-router-dom';
import { userTabs } from '../layout/UserLayout/UserSidebar';
export default function CustomerMenu() {
    return (
        <div className="absolute top-2 right-0 mt-6 z-50 w-64">
            <div className="rounded-lg shadow-full p-4 bg-white">
                {userTabs.map(tab => 
                <Link to={tab.route} key={tab.id}>
                    <div className="w-full py-2 flex items-center border-b px-3 hover:text-red-700 trans cursor-pointer text-gray-700">
                        <tab.Icon className="mr-4" />
                        <p>{tab.text}</p>
                    </div>
                </Link>)}
            </div>
        </div>
    )
}