import React from "react";

import Modal from "react-modal";

import Header from "./Header";
import Offer from "./Offer";

export default function SpecialOfferPopup({ openModal, setOpenModal }) {
  return (
    <Modal
      isOpen={openModal}
      style={customStyles}
      className="w-full outline-none absolute border border-none"
      ariaHideApp={false}
    >
      <div className="w-full h-screen">
        <div className="w-full h-full flex items-center justify-center">
          <div className="w-full sm:w-1/3 md:w-1/3 mx-8 sm:mx-0 md:mx-0 bg-white rounded-lg p-6 mt-16">
            <Header heading="Combo-2" setOpenModal={setOpenModal} />
            <div className="flex items-center justify-between">
              <p className="text-sm text-gray-600">Choose as your taste.</p>
            </div>
            <div className="mt-2 w-full rounded-lg shadow-lg bg-white border pb-2 overflow-hidden">
              <Offer heading="Choose any 2 item(s) from Clay Oven Dishes" />
              <div className="px-3 pt-2 flex justify-end items-center border-t border-gray-400">
                <div className="px-4 py-2 bg-prim text-white rounded-lg cursor-pointer">
                  <p className="">OK</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const customStyles = {
  content: {
    top: "40px",
    left: "auto",
    right: "auto",
    bottom: "auto",
    //   zIndex                : '500'
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, .8)",
  },
  modal: {
    outline: "none",
  },
};
