import React, { useEffect } from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';

import { getLoggedRiderInfo } from "../../../redux/actions/driver";
import { getCookie } from '../../../components/utilities/cookie/cookie';

import UserLayout from '../../../components/layout/UserLayout';
import UserProfileAddress from './UserProfileAddress';
import UserProfileInfo from './UserProfileInfo';
import UserProfileContact from './UserProfleContact';
import UserProfileLicense from './UserProfileLicense';
import UserProfileInsurance from './UserProfileInsurance';
import UserVehicleType from './UserVehicleType';
import UserVehicleRegistrationNo from './UserVehicleRegistrationNo';

export default function UserProfile() {
    const { driver } = useSelector(state => state.driver);
    const dispatch = useDispatch();
    const token = getCookie('token');

    useEffect(() => {
        getLoggedRiderInfo(dispatch, token)
    }, [dispatch, token]);

    return (
        <HelmetProvider>
            <Helmet>
                <title>FoodApps | Partner Inbox</title>
            </Helmet>
            <UserLayout>
                <UserProfileInfo driver={driver} />
                <UserProfileContact driver={driver} />
                <UserProfileAddress driver={driver} />
                <UserVehicleType driver={driver} />
                <UserProfileLicense driver={driver} />
                <UserProfileInsurance driver={driver} />
                <UserVehicleRegistrationNo />
            </UserLayout>
        </HelmetProvider>
    )
}