import { FiFacebook, FiInstagram, FiTwitter, FiYoutube } from "react-icons/fi";

export const footerCardData = [
    {
        id: 1,
        title: "Find",
        desc: "Restaurants or Takeaways",
        img: "https://foodapps.uk/images/assets/restaurant-new.png",
    },
    {
        id: 2,
        title: "Order",
        desc: "Delivery or Collection",
        img: "https://foodapps.uk/images/assets/shoppingcart.png",
    },
    {
        id: 3,
        title: "Delivery",
        desc: "Within 45 Minutes",
        img: "https://foodapps.uk/images/assets/delivery.png",
    }
]

export const footerLinks = [
    { id: 1, text: 'Food Allergy and T&C', url: '/user/policy' },
    { id: 2, text: 'Help', url: '/user/help' },
    { id: 3, text: 'Contact us', url: '/' },
    { id: 4, text: 'Chat', url: '/' }
];
export const socialMedia = [
    { bg: 'facebook', url: 'https://facebook.com/foodappsuk', icon: FiFacebook },
    { bg: 'instagram', url: 'https://instagram.com/foodappsuk', icon: FiInstagram },
    { bg: 'twitter', url: 'https://twitter.com/foodappsuk', icon: FiTwitter },
    { bg: 'youtube', url: 'https://www.youtube.com/channel/UCDCwDA873ighpp3TlD0Bakw', icon: FiYoutube },
];