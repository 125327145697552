import {
    GET_NOTIFICATION_FAILURE,
    GET_NOTIFICATION_SUCCESS,
    GET_ALL_NOTIFICATIONS_SUCCESS,
    DELETE_NOTIFICATION_SUCCESS,
    REMOVE_NOTIFICATION_SUCCESS
} from "../types/notification/type";
import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";

const URL = "http://localhost:5000";

export const getDriverNotifications = async (dispatch, token) => {
    try {
        const { data: { data } } = await axios.get(`${URL}/api/v1/notification/driver`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        if (data) {
            dispatch({ type: GET_ALL_NOTIFICATIONS_SUCCESS, payload: data })
        }
    } catch (err) {

        const { response: { data: { error } } } = err;
        dispatch({ type: GET_NOTIFICATION_FAILURE, payload: error })
    }
}

export const removeNotifications = async (dispatch, id, token) => {
    try {
        const { data: { data } } = await axios(`${apiBaseUrl}${apiVersionPrefix}/notification/remove-customer-notification/${id}`, {
            method: "PUT",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                "Authorization": `Bearer ${token}`,
            },
        })
        if (data) {
            dispatch({ type: REMOVE_NOTIFICATION_SUCCESS, payload: data })
        }
    } catch (err) {

        const { response: { data: { error } } } = err;
        dispatch({ type: GET_NOTIFICATION_FAILURE, payload: error })
    }
}
