import React from 'react';

import { Link } from 'react-router-dom';

export default function LoginFormHeader() {
    return (
        <div className="flex justify-center mt-2">
            <Link to="/">
                <img className="h-16" src="/img/logos/FoodApps_rider_logo.png" alt="" />
            </Link>
        </div>
    )
}
