import React from 'react';
export default function YourOrder ( ) {
    return (
        <div className="w-full h-full">
            <div className="w-full text-center">
                <p className="font-semibold text-sec">Your Order</p>
            </div>
            <div className="flex py-4 text-sm text-gray-600 px-2">
                <div className="flex flex-grow w-2/3">
                    <p className="text-sec font-semibold">1</p>
                    <p className="px-3">x</p>
                </div>
                <div className="w-1/3 text-right">
                    <p>£3.00</p>
                </div>
            </div>
            <div className="w-full p-2">
                <div className="border-b border-gray-400"></div>
            </div>
            <div className="p-2 flex text-sm text-sec font-semibold items-center justify-between">
                <p className="">Sub Total</p>
                <p className="">£3.00</p>
            </div>
            <div className="w-full p-2">
                <div className="border-b border-gray-400"></div>
            </div>
            <div className="w-full px-2 py-2">
                <div className="mt-3 flex text-sm text-gray-600 items-center justify-between">
                    <p className="">Service Charges</p>
                    <p className="">+ £0.50</p>
                </div>
                <div className="w-full mt-3">
                    <div className="border-b border-gray-400"></div>
                </div>
                <div>

                </div>
            </div>
        </div>
    )
}