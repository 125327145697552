import React from 'react';
export default function Content({title, value}) {
    return (
        <div className="flex items-center w-full">
            <div className="mt-3 w-2/4 flex justify-between">
                <div className="py-2">
                    <p className="font-semibold">{title}</p>
                </div>
                <div className="py-2">
                    <p className="">:</p>
                </div>
            </div>
            <div className="mt-3 w-2/4">
                <div className="py-2 pl-6 md:pl-20">
                    <p className="">{value}</p>
                </div>
            </div>
        </div>
    )
}