import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import UserLayout from "./../../../components/layout/UserLayout/index";
import Heading from "../../../components/utilities/Heading";
import Tabs from "../../../components/utilities/Tabs";
import { Divider } from "antd";
import Button from "./../../../components/utilities/buttons/Button";
import BoldText from "./../../../components/utilities/BoldText/index";
import { Link } from "react-router-dom";
import { useState } from "react";
import { FiEdit2, FiX } from "react-icons/fi";
import Icon from "./../../../components/utilities/Icon";
import EditWallet from "./EditWallet";
import SectionContent from "./../../../components/SectionContent/index";

const RiderWallet = () => {
  const [editable, setEditable] = useState(false);
  return (
    <HelmetProvider>
      <Helmet>
        <title>FoodApps | Rider Wallet</title>
      </Helmet>
      <UserLayout>
        <Heading>My Wallet</Heading>
        <div className="mt-4">
          <div className="border-b-2 pb-2 mt-8 flex items-center justify-between">
            <p className="text-lg font-bold">Info Details</p>
          </div>
          <div className="flex items-center w-full justify-end mr-5">
            {!editable ? (
              <Icon onClick={() => setEditable(true)} icon={FiEdit2} />
            ) : (
              <div className="flex justify-between">
                <Icon onClick={() => setEditable(false)} icon={FiX} />
              </div>
            )}
          </div>
          {!editable && (
            <div className="w-full flex flex-wrap items-center mt-2">
              <SectionContent title="Name" value="Rakib" />
              <SectionContent title="Bank Name" value="DBBL" />
              <SectionContent title="Sort Code" value="34435" />
              <SectionContent title="Account Number" value="324234234" />
            </div>
          )}
          {editable && <EditWallet />}
        </div>
      </UserLayout>
    </HelmetProvider>
  );
};

export default RiderWallet;
