import React from 'react'

export default function Button(props) {
    
    return (
        <button onClick={props.onClick} style={props.style} type={props.type} className={`${props.bgColor? `${props.bgColor} text-sm` : "bg-prim font-semibold"} text-white cursor-pointer  border shadow-lg outline-none ${props.variant==="md"? "rounded px-3 py-2":"rounded-lg px-4 py-3"} ${props.variant==="sm"? "font-bold text-xs": ""} ${props.classes}`}>
            {props.text || props.children}
        </button>
    )
}
