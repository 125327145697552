import React from 'react'
import Button from '../utilities/buttons/Button'
import AgreementCheck from '../utilities/form/AgreementCheck'
import CheckoutHeader from './CheckoutHeader'
import PaymentOptionCard from './PaymentOptionCard.js'

export default function CheckoutCard(props) {
    return (
    <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6">
        <CheckoutHeader title="Payment Option" editable={false} />
        {/* <TableNumber /> */}
        <PaymentOptionCard options={["Counter", "Cash"]} />
        <AgreementCheck
            beforeLink="Before your order please make sure your "
            link=" Food Allergy and T&amp;C."
            afterLink=" If you have anything else, please contact us."
        />
        <Button classes="mt-6 bg-prim text-white w-full" text="Proceed to Checkout" />

    </div>
    )
}