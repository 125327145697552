import React, {useEffect, useState} from 'react';

import NormalNavbar from './NormalNavbar';
import ScrolledNavbar from './ScrolledNavbar';

export default function Navbar() {
    const [changeNavbar, setChangeNavbar] = useState(null);

    useEffect(() => {
        if (typeof window != 'undefined') {
            const changeNavigation = () => {
                if (window.scrollY >= 200) {
                    setChangeNavbar(true)
                } else if (window.scrollY < 100) {
                    setChangeNavbar(false)
                }
            }
            window.addEventListener('scroll', changeNavigation)
        }
    }, [])

    return (
        <div className="trans bg-nat">
            {changeNavbar ? <ScrolledNavbar/> : <NormalNavbar/>}
        </div>
    )
}
