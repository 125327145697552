import React, { useState } from "react";
import { FiCheck } from "react-icons/fi";

export default function Checkbox(props) {
  const [checked, setChecked] = useState(false);

  return (
    <div
      style={props.containerStyle}
      className={`w-5 h-5 ${props.containerClasses}`}
    >
      <div
        style={props.style}
        onClick={() => {
          props.onClick(!checked);
          setChecked(!checked);
        }}
        className={`w-5 h-5 border-2 flex items-center justify-center border-black cursor-pointer rounded-md ${props.Classes}`}
      >
        {checked && <FiCheck />}
      </div>
    </div>
  );
}
