import {
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  GET_OTP_SUCCESS,
  GET_OTP_FAILURE,
} from "../types/customer/types";
import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";
import { setCookie } from "../../components/utilities/cookie/cookie";

const URL = "http://localhost:5000";

export const getCustomerLogin = async (dispatch, formData) => {
  try {
    const {
      data: { data, token },
    } = await axios.post(`${URL}/api/v1/customer/signin`, formData);
    console.log(formData);
    if (data) {
      setCookie("token", token, 360000);
      localStorage.setItem("customer", JSON.stringify(data));
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    // console.log(error)
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};

export const getCustomerSignup = async (dispatch, formData) => {
  try {
    const {
      data: { data, token },
    } = await axios.post(`${URL}/api/v1/customer/signup`, formData);
    if (data) {
      setCookie("token", token);
      localStorage.setItem("customer", JSON.stringify(data));
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};

export const getLoggedCustomerInfo = async (dispatch, token) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/customer/get-customer-own`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });
    }
  } catch (err) {
    console.log(err);
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};

export const createCustomerAddress = async (dispatch, obj) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${URL}/api/v1/address/create-customer-address`,
      obj.formData,
      {
        headers: {
          Authorization: `Bearer ${obj.token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};

export const updateCustomerAddress = async (
  dispatch,
  { token, id, formData }
) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${URL}/api/v1/address/update-customer-address/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data) {
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};

export const updateCustomer = async (dispatch, { token, formData }) => {
  try {
    const {
      data: { data },
    } = await axios.put(`${URL}/api/v1/customer/update-customer`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (data) {
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};

export const getCustomerByPhone = async (dispatch, formData) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${URL}/api/v1/customer/get-customer-by-phone`,
      formData
    );
    localStorage.setItem("customer", JSON.stringify(data));
    if (data) {
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};

export const resetNewPassword = async (dispatch, formData) => {
  try {
    const {
      data: { data },
    } = await axios.put(`${URL}/api/v1/customer/set-new-password`, formData);

    if (data) {
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};

export const guestCustomerLogin = async (dispatch, formData) => {
  try {
    const {
      data: { data, token },
    } = await axios.post(`${URL}/api/v1/guest/guest-signup`, formData);
    if (data) {
      setCookie("token", token, 360000);
      localStorage.setItem("customer", JSON.stringify(data));
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    // console.log(error)
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};

export const checkGuestMobile = async (dispatch, formData) => {
  try {
    const {
      data: { data },
    } = await axios.post(`${URL}/api/v1/guest/check-guest-mobile`, formData);
    if (data) {
      dispatch({ type: GET_OTP_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    // console.log(error)
    dispatch({ type: GET_OTP_FAILURE, payload: error });
  }
};

export const getAllFavourites = async (dispatch, id, token) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${URL}/api/v1/customer/get-favourite-restaurant/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    // console.log(error)
    dispatch({ type: GET_CUSTOMER_FAILURE, payload: error });
  }
};
