import React, { useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Redirect } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { FiMail, FiLock } from 'react-icons/fi';

import Image from "./../utilities/images/Image";
import LoginFormHeader from "./LoginFormHeader";
import LoginFormFooter from "./LoginFormFooter";

// import { getCustomerLogin } from '../../redux/actions/customer';
import { getDriverLogin } from '../../redux/actions/driver';
import ErrorMessage from "../utilities/form/ErrorMessage";

const SignIn = () => {

    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        await getDriverLogin(dispatch, formData);
    }


    const { error, status } = useSelector(state => state.driver);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>FoodApps Rider | Sign In</title>
            </Helmet>
            <div className="w-full h-screen bg-nat">

                <div className="relative w-full h-full flex items-center justify-center">

                    <div className="absolute top-0 right-0 w-4/12 mt-10 hidden sm:block md:block">
                        <Image
                            src="https://foodapps.uk/images/backgrounds/food4.png"
                            alt=""
                        />
                    </div>
                    <div className="absolute bottom-0 left-0 w-4/12 mt-10 hidden sm:block md:block">
                        <Image
                            src="https://foodapps.uk/images/backgrounds/food1.png"
                            alt=""
                        />
                    </div>

                    <div className="rounded-lg w-full mx-4 sm:mx-0 md:mx-0 p-4 bg-white shadow-md sm:1/3 md:w-1/3">
                        {error && <ErrorMessage message={error} />}
                        <LoginFormHeader />

                        <form className="py-2" onSubmit={handleSubmit}>
                            <div id="sign-in-button"></div>
                            <div className="py-2">
                                <div className="bg-white shadow flex items-center p-3 rounded-lg">
                                    <FiMail className="text-lg" />
                                    <input name="email" onChange={handleChange} value={formData.email} type="text" className="outline-none px-3 w-full" placeholder="Mobile Number Or Email" />

                                </div>
                                <div className="bg-white shadow flex items-center p-3 rounded-lg mt-3">
                                    <FiLock className="text-lg" />
                                    <input name="password" onChange={handleChange} value={formData.password} type="password" className="outline-none px-3 w-full" placeholder="Password" />

                                </div>

                            </div>
                            <button
                                type="submit"
                                className="w-full font-semibold rounded-lg py-3 px-6 bg-red-700 text-white outline-none mt-1"
                            >
                                Login
                            </button>
                            {/* <div className='w-full'>
                                <Link to="/guest-sign-in"
                                    className="block w-full font-semibold rounded-lg py-3 px-6 bg-red-200 text-red-700 border-red-700 border-2 outline-none mt-3 hover:bg-red-500 hover:text-white transition text-center"

                                >
                                    Join As A Guest
                                </Link>
                            </div> */}
                        </form>
                        <LoginFormFooter type="signup" />
                    </div>

                    <div className="absolute bottom-0 right-0 w-4/12 mt-10 hidden sm:block md:block">
                        <Image
                            src="https://foodapps.uk/images/backgrounds/food5.png"
                            alt=""
                        />
                    </div>
                    <div className="absolute top-0 left-0 mt-10 w-4/12 hidden sm:block md:block">
                        <Image
                            src="https://foodapps.uk/images/backgrounds/food2.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
            {status === "success" ? <Redirect to="/my-deliveries" /> : null}
        </HelmetProvider>
    )
}

export default SignIn