import React from "react";
import { useState } from "react";

const UserVehicleRegistrationUpdate = () => {
  const [isEitable, setIsEditable] = useState(false);
  return (
    <div className="update-container">
      {!isEitable ? (
        <button
          className={!isEitable ? "update-button" : "update-button active"}
          onClick={() => setIsEditable(true)}
        >
          Update Registration No
        </button>
      ) : (
        <div className="textFields">
          <div className="textFields-top">
            <label className="address-label">Registration No</label>
            <button className="close" onClick={() => setIsEditable(false)}>
              X
            </button>
          </div>
          <textarea
            name="licenseNo"
            className="address-text"
            // onChange={handelChange}
            // value={mobileNo}
          ></textarea>
          <div className="flex w-full h-5 items-center justify-center bg-grey-lighter mb-4 mt-2">
            <label className="w-64 flex flex-col items-center px-4 py-2 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-prim">
              <span className="mt-0 text-base leading-normal">
                Select a file
              </span>
              <input type="file" className="hidden" />
            </label>
          </div>
          <button className="address-submit">
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default UserVehicleRegistrationUpdate;
