import { Divider } from "antd";
import React from "react";
import Heading from "../../../components/utilities/Heading";
import ProfileInfoCard from '../../../components/utilities/cards/ProfileInfoCard';
import UserVehicleUpdate from './UserVehicleUpdate';

const UserVehicleType = ({driver}) => {
  return (
    <div className="mt-16">
      <Heading>Vehicle Type</Heading>
      <div className="sm:flex md:flex">
        {driver.mobileNo ? (
          <ProfileInfoCard title="Vehicle type" value={[driver.mobileNo]} />
        ) : (
          <ProfileInfoCard title="Vehicle type" value={["Add Vehicle type"]} />
        )}
        <UserVehicleUpdate driver={driver} />
      </div>
    </div>
  );
};

export default UserVehicleType;
