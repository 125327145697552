import React, { useState } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';

export default function CountControl(props) {
    const [count, setCount] = useState(1);
    if(count < 0) {
        setCount(0)
    }
    return (
        <div className="flex flex-col bg-nat justify-center items-center rounded-full overflow-hidden w-6">
            <FiPlus onClick={() => 
                setCount(count+1)}
                 className="p-1 w-full hover:bg-red-700 hover:text-white bg-nat h-6 cursor-pointer" />

            <button onClick={props.onClick} className="w-full bg-nat text-gray-700 h-6 text-center text-md font-semibold p-1" value={count}>{count}</button>
            
            <FiMinus onClick={() => setCount(count-1)} className="p-1 w-full hover:bg-red-700 hover:text-white bg-nat h-6 cursor-pointer" />
        </div>
    )
}