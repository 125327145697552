import React from 'react'
import Button from '../utilities/buttons/Button'
import Field from '../utilities/form/Field'
export default function CheckoutTableNumber() {
    return (
        <div className="sm:flex md:flex flex-wrap items-center mt-8">
            <div className="p-2 w-full sm:w-2/3 md:2/3">
                <div className="text-sec border border-red-700 rounded p-4">
                    <p className="mt-3 text-gray-700">Enter Table No</p>
                    <Field classes="pl-3" placeholder="Table No" />
                    <div className="flex justify-end">
                        <Button text="Save" classes="text-white cursor-pointer mt-2 px-4 py-2 rounded-lg border border-red-700 outline-none bg-prim text-white" value="Save" />
                    </div>
                </div>
            </div>
        </div>
    )
}
