import React from 'react';
import Item from '../Item';
export default function Offer ({heading}) {
    return (
        <div className="overflow-y-scroll scroll max-hieght-package">
            <div className="px-3 border-b pb-2 mt-2">
                <p className="text-md font-bold text-gray-600">{heading}</p>
            </div>
            <div className="py-2 border-b-2 border-gray-400">
                <Item title="Tandori Chicken" />
            </div>
        </div>
    )
}