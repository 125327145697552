import React from 'react';

import domtoimage from 'dom-to-image'
import FileSaver from 'file-saver';
export default function Saved () {
    const saveReceipt =  () => {
        domtoimage.toBlob(document.getElementById('orderReceived'))
        .then(function (blob) {
            FileSaver.saveAs(blob, 'RECEIPT.png');
            
        });
    }
    return (
        <div className="border border-gray-700 rounded-lg px-3 py-2 cursor-pointer">
            <p onClick={saveReceipt} className="font-bold">Save</p>
        </div>
    )
}