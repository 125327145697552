import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { HelmetProvider } from "react-helmet-async";
import UserLayout from "./../../../components/layout/UserLayout/index";
import Heading from "./../../../components/utilities/Heading";
import Button from "./../../../components/utilities/buttons/Button";
import { Link } from "react-router-dom";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

const AddRiderCard = () => {
  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [focus, setFocus] = useState("");
  return (
    <HelmetProvider>
      <Helmet>
        <title>FoodApps | Partner Bookings</title>
      </Helmet>
      <UserLayout>
        <Heading>Add Card Details</Heading>
        <div className="mt-4">
          {/* <div className="flex justify-between">
            <div className="px-2 sm:px-8 md:px-8 mt-2 sm:mt-0 md:mt-0">
              <p className="text-sm font-bold">
                <span className="fotn-thin">Your Balance</span>
              </p>
            </div>
            <div className="px-2 sm:px-8 md:px-8 mt-2 sm:mt-0 md:mt-0">
              <p className="text-sm font-bold">
                <span className="fotn-thin">£100</span>
              </p>
            </div>
          </div> */}

          {/* <div className="my-5 border border-prim p-5 rounded-md">
            <p className="text-sm font-bold">
              <span className="fotn-thin">Abu Saleh Faysal</span>
            </p>
            <p className="text-sm font-bold">
              <span className="fotn-thin">**** ***** ****</span>
            </p>

            <div className="flex justify-between">
              <div className="px-2 sm:px-8 md:px-8 mt-2 sm:mt-0 md:mt-0">
                <p className="text-sm font-bold">
                  <span className="fotn-thin">Expire Date</span>
                </p>
              </div>
              <div className="px-2 sm:px-8 md:px-8 mt-2 sm:mt-0 md:mt-0">
                <p className="text-sm font-bold">
                  <span className="fotn-thin">CVC</span>
                </p>
              </div>
            </div>

            <div className="flex justify-between">
              <div className="px-2 sm:px-8 md:px-8 mt-2 sm:mt-0 md:mt-0">
                <p className="text-sm font-bold">
                  <span className="fotn-thin">22 June 2022</span>
                </p>
              </div>
              <div className="px-2 sm:px-8 md:px-8 mt-2 sm:mt-0 md:mt-0">
                <p className="text-sm font-bold">
                  <span className="fotn-thin">0000</span>
                </p>
              </div>
            </div>
          </div> */}
          {/* <div className="flex justify-between">
            <Button>Edit</Button>
            <Link to="add-user-card">
              <Button>Add Payment Method</Button>
            </Link>
          </div> */}
        </div>

        {/* <div className="flex flex-wrap items-center mt-4">
                <div className="px-8 py-6">
                    <p className="text-2xl font-thin">No Sales Found!</p>
                </div>
            </div> */}
        <Cards
          number={number}
          name={name}
          xpiry={expiry}
          cvc={cvc}
          focused={focus}
        />
        <form action="">
          <input
            className="border border-prim rounded p-2 m-1"
            type="tel"
            name="number"
            placeholder="Card Number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            onFocus={(e) => setFocus(e.target.name)}
          />

          <input
            className="border border-prim rounded p-2 m-1"
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onFocus={(e) => setFocus(e.target.name)}
          />

          <input
            className="border border-prim rounded p-2 m-1"
            type="text"
            name="expiry"
            placeholder="MM/YY Expiry"
            value={expiry}
            onChange={(e) => setExpiry(e.target.value)}
            onFocus={(e) => setFocus(e.target.name)}
          />

          <input
            className="border border-prim rounded p-2 m-1"
            type="tel"
            name="cvc"
            placeholder="CVC"
            value={cvc}
            onChange={(e) => setCvc(e.target.value)}
            onFocus={(e) => setFocus(e.target.name)}
          />
          <div className="flex justify-end">
            <Button>Submit Card Details</Button>
          </div>
        </form>
      </UserLayout>
    </HelmetProvider>
  );
};

export default AddRiderCard;
