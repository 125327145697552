import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCookie } from '../../../components/utilities/cookie/cookie';
import { updateDriver } from '../../../redux/actions/driver';
import "./index.css";

const UserPhoneUpdate = ({driver}) => {
  
  const [isEitable,setIsEditable]=useState(false);
  const dispatch = useDispatch();


  const initialState={
    mobileNo:"",
    driverId:driver._id
  }

  
  
  const [formData,setFormData]=useState(initialState);

  const token=getCookie('token');

  const handelChange = (e) => {
    setFormData({mobileNo: e.target.value,driverId:driver._id });
  }

  const {mobileNo}=formData;

  const handleSubmitForm=()=>{
    
    updateDriver(dispatch,{formData,token});
    setIsEditable(false);
  }
  
  
  return (
    <div className="update-container">
      {
        !isEitable?(
          <button className={!isEitable?"update-button":"update-button active"} onClick={()=>setIsEditable(true)}>Update Contact Number</button>
        ):(
          <div className="textFields">
            <div className="textFields-top">
              <label className="address-label">Phone Number</label>
              <button className="close"onClick={()=>setIsEditable(false)}>X</button>
            </div>
            <textarea name="mobileNo" className="address-text" onChange={handelChange} value={mobileNo}></textarea>
            <button className="address-submit" onClick={handleSubmitForm}>Save</button>
          </div>
        )
      }
      
    </div>
  )
}

export default UserPhoneUpdate;
