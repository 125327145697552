import React from 'react';

import UserPhoneUpdate from './UserPhoneUpdate';
import Heading from '../../../components/utilities/Heading';
import ProfileInfoCard from '../../../components/utilities/cards/ProfileInfoCard';
import UserLicenseUpdate from './UserLicenseUpdate';

const UserProfileLicense = ({driver}) => {
    return (
        <div className="mt-16">
            <Heading>License No</Heading>
            <div className="sm:flex md:flex">
                {
                    driver.mobileNo?(
                        <ProfileInfoCard
                        title="License No"
                        value={[driver.mobileNo]}
                        />
                    ):(
                        <ProfileInfoCard
                        title="License no"
                        value={["Add License no"]}
                        />
                    )
                }
                <UserLicenseUpdate driver={driver}/>
            </div>   
        </div>
    );
};

export default UserProfileLicense;