import React, { useState } from 'react';
export default function Tabs(props) {
    const [selectedTab, setSelectedTab] = useState(props.tabs[0])

    return (
        <div>
            {props.variant === "prim" ?
            <div className="flex justify-start flex-grow">
                <div style={props.containerStyle} className={`w-full sm:w-auto md:w-auto flex text-white text-sm font-bold cursor-pointer items-center justify-center ${props.containerClasses}`}>
                    {props.tabs.map((tab, i) =>
                        <div
                            key={i}
                            style={props.style}
                            onClick={() => setSelectedTab(tab)}
                            className={`w-auto ${selectedTab === tab? "bg-prim": "bg-dark"} hover:bg-red-800 text-xs sm:text-sm md:text-sm trans ${(i === 0)? "rounded-l-lg": ""} ${(i === props.tabs.length-1)? "rounded-r-lg": ""} p-4 px-6 ${props.classes}`}
                        >
                            <p>{tab}</p>
                        </div>
                    )}
                </div>
            </div>
        :
            <div className="flex items-center">
                <div style={props.containerStyle} className={`rounded-lg border border-red-700 flex items-center overflow-hidden ${props.containerClasses}`}>
                {props.tabs.map((tab, i) =>
                    <div
                        key={i}
                        style={props.style}
                        onClick={() => setSelectedTab(tab)}
                        className={`px-4 py-3 cursor-pointer ${selectedTab === tab? "bg-prim text-white": "text-black hover:text-red-700 trans"} ${(i === 0 || i ===  props.tabs.length-1)? "": "border-l border-r border-red-700"} ${props.classes}`}
                    >
                        <p className="font-bold">{tab}</p>
                    </div>
                    )}
                </div>
            </div>}
        </div>
        
    )
}