import Home from "../pages/user/Home";
import Order from "../pages/user/Order";
import UserFavourite from "../pages/user/UserFavourite";
import UserMyBooking from "../pages/user/UserMyBooking";
import UserMyOrder from "../pages/user/UserMyOrder"
import UserNotifications from "../pages/user/UserNotifications";
import UserProfile from "../pages/user/UserProfile";
import UserTermsOfUse from "../pages/user/UserTermsOfUse";
import UserLogin from "../pages/user/UserLogin";
import Checkout from "../pages/user/Checkout";
import OrderReceived from "../pages/user/OrderReceived";
import OrderSummary from './../pages/user/UserMyOrder/OrderSummary';
import ForgetPassParent from "../components/LoginForm/ForgetPassParent";
import ResetPassword from "../components/LoginForm/ResetPassword";
import SignIn from "../components/LoginForm/SignIn";
import SignUp from "../components/LoginForm/SignUp";
import RiderIncome from "../pages/user/RiderIncome"
import RiderWallet from "../pages/user/RiderWallet";
import RiderInvoice from './../pages/user/RiderInvoice/index';
import AddRiderCard from './../pages/user/AddRiderCard/index';

const userRoutes = [
    {
        path: '/',
        exact: true,
        component: SignIn
    },
    // {
    //     path: '/home',
    //     exact: true,
    //     component: Home
    // },
    {
        path: '/order',
        exact: false,
        component: Order
    },
    {
        path: '/order-summary/:id',
        exact: false,
        component: OrderSummary
    },
    {
        path: '/checkout',
        exact: true,
        component: Checkout
    },
    {
        path: '/order-received',
        exact: true,
        component: OrderReceived
    },
    // {
    //     path: '/sign-in',
    //     exact: true,
    //     component: UserLogin
    // },
    {

        path: '/forget-password',
        exact: true,
        component: ForgetPassParent
    },
    {
        path: '/nearby-restaurants',
        exact: true,
        component: UserFavourite
    },
    {
        path: '/booking',
        exact: true,
        component: UserMyBooking
    },
    {
        path: '/driver',
        exact: true,
        component: UserProfile
    },
    // {
    //     path: '/my-order',
    //     exact: true,
    //     component: UserMyOrder
    // },
    {
        path: '/income',
        exact: true,
        component: RiderIncome
    },
    {
        path: '/wallet',
        exact: true,
        component: RiderWallet
    },
    {
        path: '/add-rider-card',
        exact: true,
        component: AddRiderCard
    },
    {
        path: '/invoice',
        exact: true,
        component: RiderInvoice
    },
    {
        path: '/notifications',
        exact: true,
        component: UserNotifications
    },
    {
        path: '/terms-of-use',
        exact: true,
        component: UserTermsOfUse
    },
    {
        path: "/reset-password",
        exact: true,
        component: ResetPassword
    },
    {
        path: "/sign-in",
        exact: true,
        component: SignIn
    },
    {
        path: "/sign-up",
        exact: true,
        component: SignUp
    },
    {
        path: "/my-deliveries",
        exact: true,
        component: UserMyOrder
    }
]

export default userRoutes;
