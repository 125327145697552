import React from 'react'
import CartAllergyNote from './CartAllergyNote'
import CartCheckout from './CartCheckout'
import CartHeader from './CartHeader'
import CartItem from './CartItem'

export default function Cart() {
    return (
        <div className="h-full">
            <div className="bg-white h-full rounded-lg pb-6 overflow-hidden">
                <CartHeader count={4} />
                <div className="w-full scroll border-b overflow-y-scroll h-64">
                    <CartItem />
                    <CartItem />
                    <CartItem />
                </div>
                
                <div className="text-md text-gray-800 w-full pt-2 px-4 text-center">
                    <CartAllergyNote />
                    <CartCheckout onClick={() => alert("Checkout done!")} buttonText="Checkout" totalPrice={234323.78} />
                </div>
            </div>
        </div>
    )
}
