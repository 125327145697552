import React from 'react'

export default function BookingStatus(props) {
    return (
        <div className="">
            <div style={props.containerStyle} className={`text-xs rounded-full py-1 px-3 ${props.containerClasses}`}>
                <p style={props.style} className={`font-bold ${props.classes}`}>{props.status}</p>
            </div>
        </div>
    )
}
