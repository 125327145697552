import {INCREMENT_COUNT, INCREMENT_BY_AMOUNT_COUNT, DECREMENT_COUNT} from "../types/count/types";

let initialState = {
    count: 0
};

export default function restaurantReducer(currentState = initialState, action) {
    const {count} = currentState;

    switch (action.type) {
        case INCREMENT_COUNT:
            return {...currentState, count: count + action.payload};
        case INCREMENT_BY_AMOUNT_COUNT:
            return {...currentState, count: count + action.payload};
        case DECREMENT_COUNT:
            return {...currentState, count: count - action.payload};
        default:
            return currentState;
    }
};
