import React from 'react';
import { Link } from 'react-router-dom';
export default function NormalNavbarLogo() {
    return (
        <div className="">
            <Link to="/home">
                <img className="h-16 cursor-pointer" src="/img/logos/FoodApps_rider_logo.png" alt="foodapps.uk" />
            </Link>
        </div>
    )
}