import React from 'react';
import { FiBell, FiFileText, FiHeart, FiLogOut, FiShoppingCart, FiUser } from 'react-icons/fi';
import { useLocation } from 'react-router';
import SidebarButton from '../../utilities/buttons/SidebarButton';
export const userTabs = [
    {
        id: 1, text: "My Profile", route: "/driver", Icon: FiUser,
    },{
        id: 2, text: "Nearby Restaurants", route: "/nearby-restaurants", Icon: FiHeart,
    },{
        id: 3, text: "My Delivery", route: "/my-deliveries", Icon: FiShoppingCart,
    },{
        id: 4, text: "Income", route: "/income", Icon: FiShoppingCart,
    },{
        id: 5, text: "Wallet", route: "/wallet", Icon: FiShoppingCart,
    },{
        id: 6, text: "Invoice", route: "/invoice", Icon: FiShoppingCart,
    },{
        id: 7, text: "Notification", route: "/notifications", Icon: FiBell,
    },{
        id: 8, text: "Terms of Use", route: "/terms-of-use", Icon: FiFileText,
    },{
        id: 9, text: "Log out", route: "/", Icon: FiLogOut,
     },
]
export default function UserSidebar() {const url = useLocation();
    const selectedTab = userTabs.find((tab) => tab.route === url.pathname)
    return (
        <>
            <div className="w-16 sm:w-2/12 md:w-2/12 h-full px-1 sm:px-6 md:px-6 py-4 ml-2 sm:ml-12 md:ml-12">

            </div>
            <div className="fixed sm:w-2/12 md:w-2/12 h-full bg-white px-1 sm:px-6 md:px-6 py-4 ml-2 sm:ml-0 md:ml-0 rounded-lg shadow-md">
                {userTabs.map((tab) =>
                    <SidebarButton
                        key={tab.id}
                        id={tab.id}
                        route={tab.route}
                        icon={tab.Icon}
                        text={tab.text}
                        active={selectedTab === tab? true: false}
                />
                )}                        
            </div>
        </>
    )
}