import React from 'react';

import UserPhoneUpdate from './UserPhoneUpdate';
import Heading from '../../../components/utilities/Heading';
import ProfileInfoCard from '../../../components/utilities/cards/ProfileInfoCard';

export default function UserProfileContact({driver}) {
    return (
        <div className="mt-16">
            <Heading>Contact Number</Heading>
            <div className="sm:flex md:flex">
                {
                    driver.mobileNo?(
                        <ProfileInfoCard
                        title="Phone"
                        value={[driver.mobileNo]}
                        />
                    ):(
                        <ProfileInfoCard
                        title="Phone"
                        value={["Add Contact Number"]}
                        />
                    )
                }
                <UserPhoneUpdate driver={driver}/>
            </div>   
        </div>
    )
}