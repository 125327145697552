import {
  GET_ALLERGY_SUCCESS,
  GET_ALLERGY_FAILURE,
} from "../types/allergy/type";

const initialState = {
  allergy: {},
  allergies: [],
  status: null,
  error: "",
  loading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALLERGY_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        allergies: action.payload,
        error: "",
      };

    case GET_ALLERGY_FAILURE:
      return {
        ...state,
        loading: false,
        status: "Failed",
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
