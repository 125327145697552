import React, { useEffect, useState } from "react";
import UserLayout from "../../../components/layout/UserLayout";
import Heading from "../../../components/utilities/Heading";
import RestuantButton from "./../Home/Restaurants/RestuarantsButton";
import FavouriteRestaurantContent from "./FavouriteRestaurantContent";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../../components/utilities/cookie/cookie";
import { getNearbyRestaurants } from "../../../redux/actions/driver";
import { FiSearch, FiX } from "react-icons/fi";
import { BiCurrentLocation } from "react-icons/bi";

export default function UserFavourite({ setOpenModal, restaurant }) {
  const dispatch = useDispatch();
  const token = getCookie("token");
  const driverData = JSON.parse(localStorage.getItem("driver"));
  const id = driverData._id;
  // useEffect(() => {
  //   getNearbyRestaurants(dispatch, id, token);
  // }, [dispatch, id, token]);
  const { nearbyRestaurants } = useSelector((state) => state.driver);
  console.log("nearbyRestaurants", nearbyRestaurants);
  // console.log(customer.favouriteRestaurant);
  // const favouriteRestaurant = customer.favouriteRestaurant;

  const [search, setSearch] = useState(false);
  const [formData, setFormData] = useState({ search: "" });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: [e.target.value] });
    console.log('formData >>>', formData);
  }

  const handleClick = async () => {
    setSearch(true);
    await getNearbyRestaurants(dispatch, formData.search, token);
  }

  return (
    <UserLayout>
      <Heading>Nearby Restaurants</Heading>
      <div className="w-full p-4 shadow-md">
        <div className="bg-white rounded-lg w-full overflow-hidden">
          {/* favRas&&favRest.length>0? */}
          {/* {favouriteRestaurant && favouriteRestaurant.length > 0 ?
            customer.favouriteRestaurant.map((element, index) => (
              <div className="flex flex-wrap justify-between items-center border-solid border-2 border-gray-400 rounded shadow-lg mt-5">
                <FavouriteRestaurantContent element={element} key={index} />
                <RestuantButton setOpenModal={setOpenModal} restaurant />
              </div>
            )
            ): null} */}

          <div className="py-2 mx-5">
            <div className="w-full bg-white rounded-xl flex items-center border-solid border-4 border-prim">
              <div className="px-2">
                <FiSearch className="text-gray-500 text-xl" />
              </div>
              <input
                className="px-1 p-2 flex-grow outline-none" name="search" value={formData.search}
                type="text" onChange={handleChange} placeholder="Enter your post code"
              />
              <button
                className="text-red-700 text-lg font-semibold rounded-r-lg p-4 outline-none"
              // onClick={handleLocaiton}
              >
                <BiCurrentLocation />
              </button>
              <button
                className="bg-red-700 text-white font-semibold rounded-r-lg p-4 outline-none"
                onClick={() => handleClick()}
              >
                Search
              </button>
            </div>
          </div>

          {nearbyRestaurants && nearbyRestaurants.length > 0 ?

            nearbyRestaurants.map((restaurant, index) => (
              <div>
                <div key={index} className="flex flex-wrap justify-between items-center border-solid border-2 border-gray-400 rounded shadow-lg mt-5">
                  <FavouriteRestaurantContent key={index} restaurant={restaurant} />
                </div>
              </div>
            ))
            : null}
        </div>
      </div>
    </UserLayout>
  );
}
