import React from 'react';
import { Link } from 'react-router-dom';
//
import Button from '../../../../../components/utilities/buttons/Button';


export default function IncogMobileNavbar() {
    return (
        <div className="bg-white px-5 py-3 mt-1 border-t">
            <div className="flex items-center justify-between py-2 border-b">
                <Link to="/partner">
                    <Button style={{paddingTop: ".5rem", paddingBottom: ".5rem"}}>User</Button>
                </Link>
                <Link to="/choose-partner">
                    <div className="cursor-pointer text-md text-prim-hover trans">Partner</div>
                </Link>
            </div>
        </div>
    )
}