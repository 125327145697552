import React from 'react';
import QRCode from 'react-qr-code';

export default function Qr(props) {
    
    return (
        <div style={props.containerStyle} className={`${props.containerClasses}`}>
            <div style={props.style} className={`hidden md:block mx-2 p-2 bg-nat shadow-full mr-4 md:mr-20 mb-2 ${props.classes}`}>
                <QRCode value={window.location.href} size={80} />
            </div>
            <div style={props.style} className={`md:hidden mx-2 p-1 bg-nat shadow-full mr-4 md:mr-20 mb-2 ${props.classes}`}>
                <QRCode value={window.location.href} size={54} />
            </div>
        </div>
        
 )
}
