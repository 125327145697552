import React from 'react';

export default function ErrorMessage({message}) {
    return (
        <div className="py-3 px-4 rounded-lg bg-prim text-white  flex items-center justify-between">
            <p className="font-semibold">{message}</p>
            {/* <Icon onClick={() => setClose(true)} icon={FiX} color="text-white" /> */}
        </div>
    )
}
