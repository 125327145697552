import React from "react";
import Heading from "./../../../components/utilities/Heading";
import ProfileInfoCard from "./../../../components/utilities/cards/ProfileInfoCard";
import UserVehicleRegistrationUpdate from "./UserVehicleRegistrationUpdate";

const UserVehicleRegistrationNo = () => {
  return (
    <div className="mt-16">
      <Heading>Vehicle Registration No</Heading>
      <div className="sm:flex md:flex">
        <ProfileInfoCard title="Registration no" value={["Add Registration no"]} />
        {/* <UserInsuranceUpdate driver={driver} /> */}
        <UserVehicleRegistrationUpdate />
      </div>
    </div>
  );
};

export default UserVehicleRegistrationNo;
