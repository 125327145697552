import {createSlice} from '@reduxjs/toolkit';
export const formSlice = createSlice({
    name: "form",
    initialState: {
        type:"login"
    },


    reducers: {
        logIn: state => {
            state.type="login";
        },
        signUp: state => {
            state.type="signup";
        }
    }


});

export const {logIn,signUp} = formSlice.actions;

export default formSlice.reducer;
