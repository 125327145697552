import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateDriver } from "../../../redux/actions/driver";
import { getCookie } from "../../../components/utilities/cookie/cookie";

const UserInsuranceUpdate = ({driver}) => {
  const [isEitable, setIsEditable] = useState(false);
  const dispatch = useDispatch();
  const initialState = {
    mobileNo: "",
    driverId: driver._id,
  };
  const [formData, setFormData] = useState(initialState);
  const token = getCookie("token");
  const handelChange = (e) => {
    setFormData({ mobileNo: e.target.value, driverId: driver._id });
  };

  const handleSubmitForm = () => {
    updateDriver(dispatch, { formData, token });
    setIsEditable(false);
  };

  const { mobileNo } = formData;
  return (
    <div className="update-container">
      {!isEitable ? (
        <button
          className={!isEitable ? "update-button" : "update-button active"}
          onClick={() => setIsEditable(true)}
        >
          Update Insurance No
        </button>
      ) : (
        <div className="textFields">
          <div className="textFields-top">
            <label className="address-label">Insurance No</label>
            <button className="close" onClick={() => setIsEditable(false)}>
              X
            </button>
          </div>
          <textarea
            name="licenseNo"
            className="address-text"
            onChange={handelChange}
            value={mobileNo}
          ></textarea>
          <div className="flex w-full h-5 items-center justify-center bg-grey-lighter mb-4 mt-2">
            <label className="w-64 flex flex-col items-center px-4 py-2 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-prim">
              <span className="mt-0 text-base leading-normal">Select a file</span>
              <input type="file" className="hidden" />
            </label>
          </div>
          <button className="address-submit" onClick={handleSubmitForm}>
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default UserInsuranceUpdate;
