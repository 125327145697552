import React, { useState } from 'react'
import firebase from "../../config/firebase";
import { getCustomerByPhone, resetNewPassword } from "../../redux/actions/customer";
import { useDispatch, useSelector } from "react-redux";
import Image from "./../utilities/images/Image";
import LoginFormHeader from "./LoginFormHeader";
import LoginFormFooter from "./LoginFormFooter";
import { FiMail } from 'react-icons/fi';
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
    const customerData = JSON.parse(localStorage.getItem('customer'));

    const dispatch = useDispatch();

    const [isRedirect, setIsRedirect] = useState(false)

    const [formData, setFormData] = useState({
        newPassword: "",
        confirmPassword: "",
        id: customerData._id
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        resetNewPassword(dispatch, formData);
        toast.success("New PassWord is set! Now login");
        setTimeout(() => {
            setIsRedirect(true)
        }, 1000)
    }

    const { error } = useSelector(state => state.customer);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        console.log(formData);
    }
    return (
        <div>
            <div className="w-full h-screen bg-nat">
                <ToastContainer style={{ fontSize: "1rem" }} />
                <div className="relative w-full h-full flex items-center justify-center">
                    <div className="absolute top-0 right-0 w-4/12 mt-10 hidden sm:block md:block">
                        <Image
                            src="https://foodapps.uk/images/backgrounds/food4.png"
                            alt=""
                        />
                    </div>
                    <div className="absolute bottom-0 left-0 w-4/12 mt-10 hidden sm:block md:block">
                        <Image
                            src="https://foodapps.uk/images/backgrounds/food1.png"
                            alt=""
                        />
                    </div>

                    <div className="rounded-lg w-full mx-4 sm:mx-0 md:mx-0 p-4 bg-white shadow-md sm:1/3 md:w-1/3">
                        <LoginFormHeader />

                        <form className="py-2" onSubmit={handleSubmit}>
                            <div id="sign-in-button"></div>
                            <div className="py-2">
                                <div className="bg-white shadow flex items-center p-3 rounded-lg">
                                    <FiMail className="text-lg" />
                                    <input name="newPassword" onChange={handleChange} value={formData.newPassword} type="text" className="outline-none px-3 w-full" placeholder="New Password" />

                                </div>
                                <div className="bg-white shadow flex items-center p-3 rounded-lg mt-2">
                                    <FiMail className="text-lg" />
                                    <input name="confirmPassword" onChange={handleChange} value={formData.confirmPassword} type="text" className="outline-none px-3 w-full" placeholder="Confirm Password" />

                                </div>

                            </div>
                            <button
                                type="submit"
                                className="w-full font-semibold rounded-lg py-3 px-6 bg-red-700 text-white outline-none"
                            >
                                Reset Password
                            </button>
                        </form>
                        <LoginFormFooter />
                    </div>

                    <div className="absolute bottom-0 right-0 w-4/12 mt-10 hidden sm:block md:block">
                        <Image
                            src="https://foodapps.uk/images/backgrounds/food5.png"
                            alt=""
                        />
                    </div>
                    <div className="absolute top-0 left-0 mt-10 w-4/12 hidden sm:block md:block">
                        <Image
                            src="https://foodapps.uk/images/backgrounds/food2.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
            {isRedirect ? <Redirect to="/sign-in" /> : <div>{error}</div>}
        </div>
    )
}

export default ResetPassword
