import React from "react";
import Heading from '../../../components/utilities/Heading';
import ProfileInfoCard from '../../../components/utilities/cards/ProfileInfoCard';
import UserLicenseUpdate from './UserLicenseUpdate';
import UserInsuranceUpdate from './UserInsuranceUpdate';

const UserProfileInsurance = ({driver}) => {
  return (
    <div className="mt-16">
      <Heading>Insurance No</Heading>
      <div className="sm:flex md:flex">
        {driver.mobileNo ? (
          <ProfileInfoCard title="Insurance No" value={[driver.mobileNo]} />
        ) : (
          <ProfileInfoCard title="Insurance no" value={["Add Insurance no"]} />
        )}
        <UserInsuranceUpdate driver={driver} />
      </div>
    </div>
  );
};

export default UserProfileInsurance;
