import {
  GET_FOOD_FAILURE,
  GET_FOOD_SUCCESS,
  GET_ALL_FOOD_SUCCESS,
  DELETE_FOOD_SUCCESS,
} from "../types/food/type";

const initialState = {
  food: {},
  foods: [],
  status: null,
  error: "",
  loading: true,
  message: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FOOD_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        food: action.payload,
        error: "",
      };
    case GET_ALL_FOOD_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        foods: action.payload,
        error: "",
      };
    case DELETE_FOOD_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        message: action.payload,
        error: "",
      };

    case GET_FOOD_FAILURE:
      return {
        ...state,
        loading: false,
        status: "Failed",
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
