import {
  GET_SPECIAL_OFFER_FAILURE,
  GET_SPECIAL_OFFER_SUCCESS,
  GET_ALL_SPECIAL_OFFER_SUCCESS,
  DELETE_SPECIAL_OFFER_SUCCESS,
} from "../types/specialOffer/types";

const initialState = {
  specialOffer: {},
  specialOffers: [],
  status: null,
  error: "",
  loading: true,
  message: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPECIAL_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        specialOffer: action.payload,
        error: "",
      };
    case GET_ALL_SPECIAL_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        specialOffers: action.payload,
        error: "",
      };
    case DELETE_SPECIAL_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        message: action.payload,
        error: "",
      };

    case GET_SPECIAL_OFFER_FAILURE:
      return {
        ...state,
        loading: false,
        status: "Failed",
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
