import React from 'react';
import Content from './Content';
export default function OrderDetails() {
    return (
        <>
            <Content title="Table Number" value="2" />
            <Content title="Order Time" value="12:20 AM Sep 01 2021" />
            <Content title="Payment Method" value="Counter" />
            <Content title="Kitchen Notes" value="----" />   
        </>
    )
}