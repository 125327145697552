import React from 'react';

import Heading from '../../../components/utilities/Heading';
import SubTitle from '../../../components/utilities/SubTittle';
import Text from '../../../components/utilities/Text';
import UserNameUpdate from "./UserNameUpdate";

export default function UserProfileInfo({ driver }) {
    return (
        <>
            <Heading>Your Profile</Heading>
            <div className="w-full border-b flex-col mt-6">
                <SubTitle>User ID</SubTitle>
                <div className="py-3">
                    <Text>{driver?.email}</Text>
                </div>
            </div>
            <div className="sm:flex md:flex items-center justify-between">
                <div className="w-full mr-4 flex-col mt-3">
                    <SubTitle classes="pl-1 mb-4">Full Name</SubTitle>
                    <UserNameUpdate driver={driver} />
                </div>
            </div>
        </>
    )
}