import './App.css';
import './style/index.scss'
import MasterRoute from '../src/router/MasterRoute';

function App() {
    return (
        <MasterRoute/>
    );
}

export default App;
