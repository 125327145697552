import React from 'react'
import Editable from '../utilities/buttons/Editable'
import RoundIcon from '../utilities/buttons/RoundIcon'
export default function CheckoutHeader({editable, title}) {
    return (
        <div className="flex items-center px-2">
            {editable ?
                <RoundIcon img="https://foodapps.uk/images/icons/table.png" />
            :
                <RoundIcon text="£" />
            }
            <div className="px-4 flex-grow">
                <p className="text-lg sm:text-2xl md:text-2xl">{title}</p>
            </div>
            {editable &&
            <div className="sm:px-4 md:px-4">
                <Editable onClick={(checked) => alert(checked)} />
            </div>}
        </div>
    )
}
