import React from 'react';

import Modal from 'react-modal';
import { FiX } from 'react-icons/fi'


import ItemOption from './ItemOption';
import Icon from '../../../../../components/utilities/Icon';

export default function OrderOptionsPopup ({ openModal, setOpenModal }) {
    return (
        <Modal
            isOpen={openModal}
            style={customStyles}
            className="w-full outline-none absolute border border-none"
            ariaHideApp={false}
        >
            <div className="w-full h-screen flex items-center justify-center">
                <div className="w-full sm:w-1/3 md:w-1/3 mx-8 sm:mx-0 md:mx-0 bg-white rounded-lg p-6 mt-16">
                    <div className="flex justify-end">
                        <Icon onClick={() => setOpenModal(false)} icon={FiX} color="text-gray-600 text-2xl" />
                    </div>
                    <div className="mt-2 w-full rounded-lg shadow-lg bg-white border py-4">
                        <ItemOption title="Medium" price="34" />
                    </div>
                </div>
            </div>

        </Modal>
    )
}
const customStyles = {
    content : {
      top                   : '30px',
      left                  : 'auto',
      right                 : 'auto',
      bottom                : 'auto',
    //   zIndex                : '500'
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, .8)'
      },
      modal: {
          outline: "none"
      }
  };