import React, { useState } from 'react';
//
import { FiMenu } from 'react-icons/fi';
import IncogMobileNavbar from './IncogMobleNavbar';



function MobileNavbar({type}) {
    const [openMenu, setOpenMenu] = useState(false)
    return (
        <div className="block sm:block md:hidden lg:hidden items z-100 bg-gray center w-full shadow-lg">
            <div className="w-full flex items-center justify-between px-5  py-2 fixed z-50  bg-white">
                <div>
                    <img alt="foodapps.uk" className="h-10 cursor-pointer" src="/img/logos/FoodApps_rider_logo.png" />
                </div>
                <FiMenu onClick={() => setOpenMenu(!openMenu)} className="cursor-pointer text-lg" />
            </div>
            <div className="w-full">
                { openMenu &&
                <div className="fixed z-50 w-full mt-12">
                    <IncogMobileNavbar />
                </div>}
            </div>
        </div>
    )
}

export default MobileNavbar
