import React from "react";
import RoundedImage from './../../../components/utilities/images/RoundedImg';
import { BsHeartFill } from 'react-icons/bs';
import { FiShoppingBag } from 'react-icons/fi';
import { RiBikeLine } from "react-icons/ri";

const FavouriteRestaurantContent = (props) => {
  const { restaurant } = props;
  console.log('propssss in ', restaurant.restaurantDelivery);
  return (
    <div className="cursor-pointer w-full md:w-2/5 flex-grow md:flex items-center p-3 md:py-5">
      <div className="w-auto md:w-32 flex justify-center">
        <RoundedImage src="img/img1.jpg" />
      </div>
      <div className="w-full truncate px-2">
        <div className="flex items-center">
          <p className="text-lg sm:text-xl md:text-xl font-bold text-sec truncate text-gray-800">
            {restaurant?.restaurant?.name}
          </p>
          {/* <div className="px-4 text-center">
            <BsHeartFill className="text-2xl text-red-600" />
          </div> */}
        </div>
        <div className="sm:flex md:flex items-center">
          <p className="py-1 text-gray-600 text-sm font-bold truncate">
            {restaurant?.address?.address}
          </p>
        </div>
        <div className="mt-2 w-full">
          <div className="w-full text-prim flex mt-2">
            {/* <FiShoppingBag className="text-prim" /> */}
            {/* <p className="px-2 text-xs font-bold">{restaurant?.restaurantTiming?.collectionTime} Mins,</p> */}
            <RiBikeLine className="text-prim" />
            <p className="px-2 text-xs font-bold">
              {Math.round(restaurant?.distance)} Miles Away
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavouriteRestaurantContent;
